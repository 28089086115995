import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useLocale, useRedirectWhenPspMerchantAccountChanges} from '@/hooks';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {SplitConfigurationGroupPublicStatus, fromMinorUnits} from '@zentact/common';
import {Breadcrumbs, Loading, Typography, useRequiredParams} from '@zentact/ui-tailwind';
import {useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {
  RecurringFeeGroupForm,
  TenantIntervalFeeGroupFormData,
  getTenantIntervalFeeGroupFormSchema,
} from './recurring-fee-group-form/recurring-fee-group-form';
import {RecurringFeeGroupActions} from './recurring-fee-groups-list/recurring-fee-group-actions';

export const RecurringFeeGroupDetailsPage = () => {
  useRedirectWhenPspMerchantAccountChanges(RoutePath.RECURRING_FEE_GROUPS);
  const {tenantIntervalFeeGroupId} = useRequiredParams<{tenantIntervalFeeGroupId: string}>();
  const {
    data: tenantIntervalFeesGroupData,
    isLoading,
    refetch,
  } = trpc.tenantIntervalFeesGroup.byId.useQuery(tenantIntervalFeeGroupId, {
    enabled: !!tenantIntervalFeeGroupId,
  });

  const {currency} = useStore();
  const {locale} = useLocale();

  const form = useForm<TenantIntervalFeeGroupFormData>({
    resolver: zodResolver(getTenantIntervalFeeGroupFormSchema(locale, currency)),
  });

  useEffect(() => {
    if (!tenantIntervalFeesGroupData) {
      return;
    }

    const {name, status, perDeviceFee, tenantIntervalFees, showInRegistration} =
      tenantIntervalFeesGroupData;

    form.reset({
      name: name,
      isActive: status === SplitConfigurationGroupPublicStatus.ACTIVE,
      perDeviceFee: perDeviceFee ? fromMinorUnits(perDeviceFee, currency) : 0,
      showInRegistration,
      tenantIntervalFees: tenantIntervalFees
        ? tenantIntervalFees.map(fee => ({
            name: fee.name ?? '',
            amount: fromMinorUnits(fee.amount, currency),
            period: fee.period,
            annualFeeCollectMonth: fee.annualFeeCollectMonth
              ? fee.annualFeeCollectMonth.toString()
              : undefined,
          }))
        : [],
    });
  }, [tenantIntervalFeesGroupData]);

  const breadcrumbs = useMemo(
    () => [
      {name: t`Configure`, href: RoutePath.CONFIGURE, current: false, disabled: true},
      {name: t`Recurring Fee Groups`, href: RoutePath.RECURRING_FEE_GROUPS, current: false},
      {
        name: tenantIntervalFeesGroupData?.name ?? t`Details`,
        href: '#',
        current: true,
      },
    ],
    [tenantIntervalFeesGroupData]
  );

  if (isLoading || !tenantIntervalFeesGroupData) {
    return (
      <div className="flex items-center justify-center h-80">
        <Loading mode="inline" />
      </div>
    );
  }

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="flex items-center justify-between mt-4">
        <Typography variant="header-page">{tenantIntervalFeesGroupData?.name}</Typography>
        <RecurringFeeGroupActions
          tenantIntervalFeeGroup={tenantIntervalFeesGroupData}
          refetchData={refetch}
          view="button"
        />
      </div>

      <div className="py-3 text-sm text-gray-700">
        <Trans>
          Set up how to charge your merchants. You can have a default set of fees and create custom
          configurations for different circumstances. You will apply these when adding a new
          merchant. Monthly fees will apply to all merchants onboarded by the 20th day of the month.
        </Trans>
      </div>
      <form>
        <RecurringFeeGroupForm
          form={form}
          readOnly
          merchantsCount={tenantIntervalFeesGroupData?.merchantsCount}
        />
      </form>
    </div>
  );
};
