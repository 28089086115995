import {DateTime} from 'luxon';
import {z} from 'zod';
import {StorePublicStatus} from '../types';

export const dashboardSearchParamsSchema = z.object({
  organizationId: z.string().nullable().optional(),
  startDate: z
    .date()
    .or(z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').toJSDate()))
    .nullable()
    .optional(),
  endDate: z
    .date()
    .or(
      z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').endOf('day').toJSDate())
    )
    .nullable()
    .optional(),
});

export const profitabilitySearchParamsSchema = z.object({
  organizationId: z.string().nullable().optional(),
  startDate: z
    .date()
    .or(z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').toJSDate()))
    .nullable()
    .optional(),
  endDate: z
    .date()
    .or(
      z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').endOf('day').toJSDate())
    )
    .nullable()
    .optional(),
  merchantSortColumnId: z.string().nullable().optional(),
  merchantSortValue: z.literal('asc').or(z.literal('desc')).nullable().optional(),
  organizationSortColumnId: z.string().nullable().optional(),
  organizationSortValue: z.literal('asc').or(z.literal('desc')).nullable().optional(),
  selectedNavigationTab: z
    .literal('organizations')
    .or(z.literal('merchants'))
    .nullable()
    .optional(),
});

export const feeGroupsSearchParamsSchema = z.object({
  name: z.string().optional(),
});

export const accountActivitySearchParamsSchema = z.object({
  startDate: z
    .date()
    .or(z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').toJSDate()))
    .optional(),
  endDate: z
    .date()
    .or(
      z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').endOf('day').toJSDate())
    )
    .optional(),
  organizationId: z.string().nullable().optional(),
  merchantAccountId: z.string().nullable().optional(),
  storeId: z.string().optional(),
  batchId: z.string().optional(),
  category: z
    .array(z.string())
    .or(z.string().transform(str => str.split(',')))
    .optional(),
});

export type AccountActivityFilters = z.infer<typeof accountActivitySearchParamsSchema>;

export const paymentsSchema = z.object({
  status: z
    .array(z.string())
    .or(z.string().transform(str => str.split(',')))
    .optional(),
  pspReferenceId: z.string().optional(),
  reference: z.string().optional(),
  authCode: z.string().optional(),
  paymentMethod: z
    .array(z.string())
    .or(z.string().transform(str => str.split(',')))
    .optional(),
  source: z
    .array(z.string())
    .or(z.string().transform(str => str.split(',')))
    .optional(),
  shopperEmail: z.string().email().optional(),
  selectedMerchantAccount: z.string().optional(),
  storeId: z.string().optional(),
  selectedOrganization: z.string().optional(),
  startDate: z
    .date()
    .or(z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').toJSDate()))
    .nullable()
    .optional(),
  endDate: z
    .date()
    .or(
      z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').endOf('day').toJSDate())
    )
    .nullable()
    .optional(),
  shopperId: z.string().optional(),
  batchId: z.string().optional(),
  dateRangeFilterBy: z.enum(['transaction', 'refund', 'chargeback']).optional(),
  pageIndex: z.coerce
    .number()
    .optional()
    .transform(value => {
      if (value === undefined) {
        return undefined;
      }
      return value >= 0 ? value : undefined;
    }),
  pageSize: z.coerce
    .number()
    .optional()
    .transform(value => {
      if (value === undefined) {
        return undefined;
      }
      return value >= 0 ? value : undefined;
    }),
});

export type PaymentsFilters = z.infer<typeof paymentsSchema>;

export const declinedPaymentsSchema = z.object({
  status: z
    .array(z.string())
    .or(z.string().transform(str => str.split(',')))
    .optional(),
  pspReferenceId: z.string().optional(),
  reference: z.string().optional(),
  storeId: z.string().optional(),
  paymentMethod: z
    .array(z.string())
    .or(z.string().transform(str => str.split(',')))
    .optional(),
  source: z
    .array(z.string())
    .or(z.string().transform(str => str.split(',')))
    .optional(),
  shopperEmail: z.string().email().optional(),
  selectedMerchantAccount: z.string().optional(),
  selectedOrganization: z.string().optional(),
  startDate: z
    .date()
    .or(z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').toJSDate()))
    .nullable()
    .optional(),
  endDate: z
    .date()
    .or(
      z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').endOf('day').toJSDate())
    )
    .nullable()
    .optional(),
  shopperId: z.string().optional(),
  refusalReason: z
    .array(z.string())
    .or(z.string().transform(str => str.split(',')))
    .optional(),
  dateRangeFilterBy: z.enum(['transaction', 'refund', 'chargeback']).optional(),
});

export type DeclinedPaymentsFilters = z.infer<typeof declinedPaymentsSchema>;

export const savedPaymentMethodsSchema = z.object({
  name: z.string().optional(),
  merchantShopperId: z.string().optional(),
  email: z.string().optional(),
  selectedMerchantAccount: z.string().optional(),
});

export type SavedPaymentMethodsFilters = z.infer<typeof savedPaymentMethodsSchema>;

export const webhookSchema = z.object({
  name: z.string().optional(),
  merchantShopperId: z.string().optional(),
  email: z.string().optional(),
  selectedMerchantAccount: z.string().optional(),
});

export type WebhookFilters = z.infer<typeof webhookSchema>;

export const apiKeysSchema = z.object({
  name: z.string().optional(),
});

export type ApiKeysFilters = z.infer<typeof apiKeysSchema>;

export const merchantsFiltersSchema = z.object({
  status: z
    .array(z.string())
    .or(z.string().transform(str => str.split(',')))
    .optional(),
  organizationId: z.string().optional(),
  businessName: z.string().optional(),
  splitConfigurationGroupId: z.string().optional(),
  tenantIntervalFeesGroupId: z.string().optional(),
  reservesApplied: z.string().optional(),
});

export type MerchantsFilters = z.infer<typeof merchantsFiltersSchema>;

export const storesFiltersSchema = z.object({
  organizationId: z.string().optional(),
  selectedMerchantAccount: z.string().optional(),
  displayName: z.string().optional(),
  statuses: z
    .array(z.string())
    .or(z.string().transform(str => str.split(',').map(s => s as StorePublicStatus)))
    .optional(),
});

export type StoresFilters = z.infer<typeof storesFiltersSchema>;
