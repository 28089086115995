import {I18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {
  PayoutsSummaryOutput,
  TenantAccountActivityDetailsOutput,
  TenantAccountActivitySummaryOutput,
} from '@zentact/api/src/trpc/routers/financeRouter';
import {
  CSV_EXPORT_FULL_DATE_FORMAT,
  CurrencyCode,
  LocaleCode,
  formatAccountingWithoutCurrency,
  formatAmountWithoutCurrency,
  formatTransferCategory,
} from '@zentact/common';
import {ExportToCsv} from '@zentact/ui-tailwind';
import {formatDescription} from '@zentact/ui-tailwind/layout/finance/account-activity-details/columns';
import {getPayoutDateTime} from '@zentact/ui-tailwind/layout/finance/payoutDateFormat';
import {DateTime} from 'luxon';

export const exportToCsvTenantAccountActivityDetails = (
  accountActivityDetails: TenantAccountActivityDetailsOutput['rows'],
  i18n: I18n
) => {
  const exporter = new ExportToCsv({
    filename: `tenant-account-activity-details-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!accountActivityDetails.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    accountActivityDetails.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Transaction Date`]: DateTime.fromISO(row.creationDate).toFormat(
        CSV_EXPORT_FULL_DATE_FORMAT
      ),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Value Date`]: getPayoutDateTime(row.valueDate).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Merchant Reference ID`]: row.paymentMerchantReference || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Store ID`]: row.merchantAccount.primaryStore?.id || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Store Reference ID`]: row.merchantAccount.primaryStore?.storeReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Status`]: row.status,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Description`]:
        formatDescription(row.description, i18n, 'merchantAccount' in row) || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Currency`]: row.currency,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Amount`]: formatAmountWithoutCurrency(
        row.amount * (row.direction === 'outgoing' ? -1 : 1),
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Category`]: formatTransferCategory(row),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Type`]:
        row.type === 'internalTransfer'
          ? i18n._('Internal Transfer')
          : row.type[0]?.toUpperCase() + row.type.slice(1), // Capitalize type
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Transaction Id`]: row.pspPaymentReference || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Batch Id`]: row.batchId || i18n._('N/A'),
    }))
  );
};

export const exportToCsvTenantPayouts = (
  fullTenantPayoutsList: PayoutsSummaryOutput,
  i18n: I18n
) => {
  const exporter = new ExportToCsv({
    filename: `tenant-payouts-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!fullTenantPayoutsList.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    fullTenantPayoutsList.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Transfer Date`]: DateTime.fromISO(row.valueDate).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Currency`]: row.currency,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Deposit Amount`]: formatAmountWithoutCurrency(
        row.amount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Reference`]: row.reference,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Bank Account`]: row.bankAccountLast4 || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Account Type`]: row.bankAccountType || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Status`]: row.status,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Estimated Arrival Time`]: row.estimatedArrivalTime
        ? DateTime.fromISO(row.estimatedArrivalTime).toFormat(CSV_EXPORT_FULL_DATE_FORMAT)
        : '',
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Batch Id`]: row.batchId || i18n._('N/A'),
    }))
  );
};

export const exportToCsvTenantAccountActivitySummary = (
  accountActivitySummary: TenantAccountActivitySummaryOutput,
  i18n: I18n
) => {
  const exporter = new ExportToCsv({
    filename: `tenant-account-activity-summary-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!accountActivitySummary.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    accountActivitySummary.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Payout Date`]: DateTime.fromISO(row.valueDay).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Capture Date`]: DateTime.fromISO(row.transactionDay).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Currency`]: row.currency,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Credits`]: formatAmountWithoutCurrency(
        row.creditsAmount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Number of Credits`]: row.creditsCount,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Debits`]: formatAccountingWithoutCurrency(
        -row.debitsAmount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Number of Debits`]: row.debitsCount,
      // biome-ignore lint/complexity/useLiteralKeys: CSV Export format
      [t`Net`]: formatAccountingWithoutCurrency(
        row.creditsAmount - row.debitsAmount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
    }))
  );
};
