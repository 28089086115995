import {Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {TableWrapper} from '@zentact/ui-tailwind';
import {UseFieldArrayRemove} from 'react-hook-form';
import {getActiveRulesColumns} from './columns';
import {SplitConfigurationRule} from './transaction-fee-group-form';

type Props = {
  rulesList?: SplitConfigurationRule[];
  isLoading?: boolean;
  readOnly?: boolean;
  remove: UseFieldArrayRemove;
};

export const PaymentRulesList = ({rulesList, isLoading, remove, readOnly}: Props) => {
  const {i18n} = useLingui();

  return (
    <TableWrapper
      columns={getActiveRulesColumns({
        i18n,
        remove,
        readOnly,
      })}
      data={rulesList || []}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No Payment Rules</Trans>
          </h3>
        </div>
      }
      isLoading={isLoading}
    />
  );
};
