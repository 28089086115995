import {I18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {MerchantsRevenueListOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CurrencyCode,
  LocaleCode,
  formatAmount,
  formatLocaleDate,
  formatPercentage,
} from '@zentact/common';
import {ExportToCsv} from '@zentact/ui-tailwind';

export const exportMerchantsRevenueCSV = (
  list: MerchantsRevenueListOutput,
  startDate: Date,
  endDate: Date,
  i18n: I18n
) => {
  const dates = `${formatLocaleDate(startDate, 'short')}-${formatLocaleDate(endDate, 'short')}`
    .toLowerCase()
    .replaceAll(' ', '-');
  const exporter = new ExportToCsv({
    filename: `merchants-profitability-report-${dates}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!list.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    list.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`PSP Merchant Account`]: row.pspMerchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Merchant Account Reference ID`]: row.tenantMerchantReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Merchant Account`]: row.businessName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Organization`]: row.organizationName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Organization Reference ID`]: row.organizationReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payments Count`]: row.totalPaymentsCount,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Average Volume`]: formatAmount(
        row.averageVolume,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Total Volume`]: formatAmount(
        row.totalVolume,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Net`]: formatAmount(row.net, i18n.locale as LocaleCode, row.currency as CurrencyCode),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Collected Fees`]: formatAmount(
        row.totalRevenue + row.processingCost,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Processing Cost`]: formatAmount(
        row.processingCost,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Profit`]: formatAmount(
        row.totalRevenue,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Profit %`]: formatPercentage(row.totalVolume, row.totalRevenue, i18n.locale as LocaleCode),
    }))
  );
};
