import {I18n} from '@lingui/core';
import {Trans} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import {MerchantAccountDetailsOutput} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {StoresListItem, StoresListOutput} from '@zentact/api/src/trpc/routers/storeRouter';
import {TableColumnSize, formatLocaleDate} from '@zentact/common';
import {
  FlatPillWithDot,
  HighlightedText,
  TruncatedText,
  getLocalizedStatusMap,
  getStoreLocalizedStatusMap,
  getTableMeta,
  merchantAccountStatusToColor,
  storeStatusToColor,
} from '@zentact/ui-tailwind';
import {StoreActions} from '../store-actions';

const columnsHelper = createColumnHelper<StoresListOutput['rows'][0]>();

type Props = {
  mode?: 'onlyPending';
  hideMerchantSearch?: boolean;
  hideAllSorting?: boolean;
  openDetailsPanel?: (row: StoresListItem) => void;
  openAssignRecurringFeeGroupPanel?: (
    row: MerchantAccountDetailsOutput | StoresListItem | null
  ) => void;
  openAssignStoreFeeGroupPanel: (row: StoresListItem | null) => void;
  openEditStorePanel: (id: string) => void;
  openUpdateSettlementDelayDays: (row: StoresListItem | null) => void;
  i18n: I18n;
  triggerRefetch: () => void;
};
export const getStoresListColumns = ({
  hideMerchantSearch,
  hideAllSorting,
  openAssignStoreFeeGroupPanel,
  openUpdateSettlementDelayDays,
  openEditStorePanel,
  i18n,
  triggerRefetch,
}: Props) => {
  const organizationNameColumn = columnsHelper.display({
    cell: props => <TruncatedText text={props.row.original.organization.name} />,
    header: () => <Trans>Organization</Trans>,
    id: 'organizationName',
    meta: {
      collapseAt: '2xl',
      sort: !hideAllSorting
        ? {
            isSortable: true,
          }
        : undefined,
    },
  });
  const merchantAccountColumn = columnsHelper.display({
    cell: props => <TruncatedText text={props.row.original.merchantAccount.businessName} />,
    id: 'businessName',
    header: () => <Trans>Merchant Account</Trans>,
    meta: {
      collapseAt: 'md',
    },
  });
  const storeNameColumn = columnsHelper.display({
    cell: props => {
      const {filterValues} = getTableMeta(props.table);
      const searchString = filterValues?.displayName as string;
      const textContent = props.row.original.displayName || i18n._('N/A');

      if (hideMerchantSearch) {
        return textContent;
      }

      if (searchString) {
        return (
          <HighlightedText
            text={textContent}
            highlight={searchString}
            className="break-all text-wrap"
          />
        );
      }
      return <TruncatedText text={textContent} />;
    },
    id: 'displayName',
    header: () => <Trans>Store</Trans>,
  });
  const actionsColumn = columnsHelper.display({
    id: 'actions',
    cell: props => (
      <StoreActions
        row={props.row.original}
        openAssignStoreFeeGroupPanel={openAssignStoreFeeGroupPanel}
        openUpdateSettlementDelayDays={openUpdateSettlementDelayDays}
        openEditStorePanel={() => openEditStorePanel(props.row.original.id)}
        viewType="dots-button"
        triggerRefetch={triggerRefetch}
      />
    ),
    size: TableColumnSize.ACTIONS,
  });
  return [
    storeNameColumn,
    merchantAccountColumn,
    organizationNameColumn,
    columnsHelper.accessor(row => row.id, {
      id: 'storeId',
      cell: info => info.getValue(),
      header: () => <Trans>Store ID</Trans>,
      meta: {
        visibleAt: 'lg',
      },
    }),
    columnsHelper.accessor(row => row.storeReferenceId, {
      id: 'storeReferenceId',
      cell: info => info.getValue(),
      header: () => <Trans>Reference ID</Trans>,
      meta: {
        visibleAt: 'full',
      },
    }),
    columnsHelper.accessor(
      row =>
        row.splitConfigurationGroup?.name ||
        row.merchantAccount.merchantAccountRegistration?.splitConfigurationGroup?.name,
      {
        id: 'splitConfigurationGroupName',
        cell: info => <TruncatedText text={info.getValue() || i18n._('N/A')} maxLength={25} />,
        header: () => <Trans>Transaction Fee Group</Trans>,
        meta: {
          collapseAt: '2xl',
          visibleAt: 'lg',
        },
      }
    ),
    columnsHelper.accessor(row => row.status, {
      id: 'status',
      cell: info => (
        <FlatPillWithDot
          color={storeStatusToColor[info.getValue()] || 'blue'}
          label={getStoreLocalizedStatusMap(i18n)[info.getValue()] || info.getValue()}
        />
      ),
      header: () => <Trans>Status</Trans>,
      meta: {
        collapseAt: 'sm',
      },
      size: TableColumnSize.XXS,
    }),
    columnsHelper.accessor(row => row.merchantAccount.status, {
      id: 'account status',
      cell: info => (
        <FlatPillWithDot
          color={merchantAccountStatusToColor[info.getValue()] || 'blue'}
          label={getLocalizedStatusMap(i18n)[info.getValue()] || info.getValue()}
        />
      ),
      header: () => <Trans>Account Status</Trans>,
      meta: {
        collapseAt: 'sm',
      },
      size: TableColumnSize.XXS,
    }),
    columnsHelper.accessor('updatedAt', {
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime'),
      header: () => <Trans>Last Active</Trans>,
      meta: {
        sort: !hideAllSorting
          ? {
              isSortable: true,
            }
          : undefined,
      },
      size: TableColumnSize.S,
    }),
    actionsColumn,
  ];
};
