import {useStore} from '@/store';
import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {
  CurrencyCode,
  ExtendedPaymentMethodEnumDisplayMap,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatAmountWithoutCurrency,
} from '@zentact/common';
import {UseFieldArrayRemove} from 'react-hook-form';
import {RuleActions} from './rule-actions';
import {SplitConfigurationRule} from './transaction-fee-group-form';

const columnsHelper = createColumnHelper<SplitConfigurationRule>();

type Props = {
  i18n: I18n;
  remove: UseFieldArrayRemove;
  readOnly?: boolean;
};
export const getActiveRulesColumns = ({i18n, remove, readOnly}: Props) => {
  const {currency} = useStore();

  return [
    columnsHelper.accessor('paymentMethod', {
      cell: info => ExtendedPaymentMethodEnumDisplayMap[info.getValue()],
      header: () => i18n._('Payment Method'),
      size: TableColumnSize.XXS,
    }),
    columnsHelper.accessor('pricingModel', {
      cell: info => info.getValue() || i18n._('N/A'),
      header: () => i18n._('Commission Type'),
      meta: {
        visibleAt: 'lg',
      },
    }),
    columnsHelper.accessor('shopperInteraction', {
      cell: info => info.getValue() || i18n._('N/A'),
      header: () => i18n._('Payment Entry Mode'),
      meta: {
        visibleAt: 'lg',
      },
    }),
    columnsHelper.display({
      id: 'Commission',
      cell: row =>
        `%${formatAmountWithoutCurrency(
          row.row.original.splitPercentage || 0,
          i18n.locale as LocaleCode,
          currency as CurrencyCode
        )} + ${formatAmount(
          row.row.original.splitFixed,
          i18n.locale as LocaleCode,
          currency as CurrencyCode
        )}`,
      header: () => i18n._('Commission'),
    }),
    ...(readOnly
      ? []
      : [
          columnsHelper.display({
            id: 'actions',
            cell: props => (
              <div className="flex justify-center">
                <RuleActions index={props.row.index} remove={remove} />
              </div>
            ),
            size: TableColumnSize.ACTIONS,
          }),
        ]),
  ];
};
