import {useLocale} from '@/hooks';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {ExtendedPaymentMethodEnum, ExtendedPaymentMethodType} from '@zentact/common';
import {PricingModel} from '@zentact/db';
import {Button, SlideOverWithBrandedHeader} from '@zentact/ui-tailwind';
import {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {AddRuleForm, AddRuleFormData, addRuleFormSchema} from './add-rule-form';

type AddRuleProps = {
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
  handleAddRule: (rule: AddRuleFormData) => void;
  existingRules: {
    paymentMethod: ExtendedPaymentMethodType;
    shopperInteraction: 'Ecommerce' | 'ContAuth' | 'Moto' | 'POS' | 'ANY';
  }[];
};

export const AddRulePanel = ({isOpen, onClose, handleAddRule, existingRules}: AddRuleProps) => {
  const {currency} = useStore();
  const {locale} = useLocale();

  const form = useForm<AddRuleFormData>({
    resolver: zodResolver(addRuleFormSchema(locale, currency)),
    defaultValues: {
      pricingModel: 'FLAT' as PricingModel,
      paymentMethod: ExtendedPaymentMethodEnum.ANY,
      shopperInteraction: 'ANY',
    },
  });

  const {handleSubmit, reset} = form;

  useEffect(() => {
    reset({
      pricingModel: 'FLAT' as PricingModel,
      paymentMethod: ExtendedPaymentMethodEnum.ANY,
      shopperInteraction: 'ANY',
    });
  }, [existingRules, reset]);

  const onSubmit = useCallback(async (data: AddRuleFormData) => {
    handleAddRule(data);
    onClose();
    reset();
  }, []);

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={t`Add new Rule`}
      closeHandler={onClose}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button variant="primary" size="lg" className="w-fit" onClick={handleSubmit(onSubmit)}>
              <Trans>Add New Rule</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <AddRuleForm form={form} existingRules={existingRules} />
      </form>
    </SlideOverWithBrandedHeader>
  );
};
