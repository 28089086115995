import {ShopperInteractionPublicEnum} from '../types';

export const FEE_MIN_AND_MAX_RESTRICTIONS = {
  minFixedFee: 0,
  maxFixedFee: 1000, // $10

  minInteracSplitFixed: 0,
  maxInteracSplitFixed: 10000,

  minAmexSplitFixed: 0,
  maxAmexSplitFixed: 10000,

  minAchSplitFixed: 10,
  maxAchSplitFixed: 1000,

  minPercentage: 0,
  maxPercentage: 1000, // 100%

  minPerRefundFee: 0,
  maxPerRefundFee: 10000, // $100

  minPerChargebackFee: 0,
  maxPerChargebackFee: 10000, // $100

  minPerDeviceFee: 0,
  maxPerDeviceFee: 50000, // $500

  minTenantIntervalFee: 0,
  maxTenantIntervalFee: 100000, // $1000
};

export const shopperInteractionLabels: Record<ShopperInteractionPublicEnum, string> = {
  [ShopperInteractionPublicEnum.Any]: 'ANY – All sales channels',
  [ShopperInteractionPublicEnum.Ecommerce]: 'Ecommerce – Online payments',
  [ShopperInteractionPublicEnum.Pos]: 'POS – In-person terminal payments',
  [ShopperInteractionPublicEnum.ContAuth]: 'ContAuth – Stored card or subscriptions',
  [ShopperInteractionPublicEnum.Moto]: 'Moto – Mail or phone orders',
} as const;

export const allShopperInteractions = Object.values(
  ShopperInteractionPublicEnum
) as ShopperInteractionPublicEnum[];
