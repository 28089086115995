import {Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {MerchantAccountDetailsOutput} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {StoresListItem, StoresListOutput} from '@zentact/api/src/trpc/routers/storeRouter';
import {TableSortValue, TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getStoresListColumns} from './columns';

type Props<TFilters extends Record<string, unknown>> = {
  storesList?: StoresListOutput;
  filters?: TFilters;
  setFilters?: (params: TFilters) => void;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string> | null>>;
  openDetailsPanel: (row: StoresListItem) => void;
  openAssignRecurringFeeGroupPanel?: (
    row: MerchantAccountDetailsOutput | StoresListItem | null
  ) => void;
  openAssignStoreFeeGroupPanel: (row: StoresListItem | null) => void;
  openEditStorePanel: (id: string) => void;
  openUpdateSettlementDelayDays: (row: StoresListItem | null) => void;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  refetch: () => void;
  mode?: 'onlyPending';
  hideMerchantSearch?: boolean;
  hideAllSorting?: boolean;
  isLoading?: boolean;
};

export const StoresList = <TFilters extends Record<string, unknown>>({
  storesList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  refetch,
  mode,
  hideMerchantSearch,
  hideAllSorting,
  openDetailsPanel,
  openAssignRecurringFeeGroupPanel,
  openEditStorePanel,
  openUpdateSettlementDelayDays,
  openAssignStoreFeeGroupPanel,
  isLoading,
}: Props<TFilters>) => {
  const {i18n} = useLingui();
  return (
    <TableWrapper
      data={storesList?.rows ?? []}
      pageCount={storesList?.pagination.pageCount ?? 0}
      columns={getStoresListColumns({
        triggerRefetch: refetch,
        mode,
        hideMerchantSearch,
        hideAllSorting,
        openDetailsPanel,
        openAssignRecurringFeeGroupPanel,
        openUpdateSettlementDelayDays,
        openEditStorePanel,
        openAssignStoreFeeGroupPanel,
        i18n,
      })}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        if (setFilters) {
          setFilters({[id]: value} as TFilters);
        }
      }}
      sortValue={!hideAllSorting ? sort : undefined}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No Stores Available</Trans>
          </h3>
          <span className="text-sm text-gray-500">
            <Trans>As stores are added, they will appear here for you to manage.</Trans>
          </span>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      onRowClick={openDetailsPanel}
      excludeLastColumnFromRowClick
      isLoading={isLoading}
    />
  );
};
