import {Menu} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import React, {ReactElement} from 'react';
import {cn} from '../utils';

type Props<T extends string> = {
  label: string;
  selected?: T[];
  options: Array<{
    element: ReactElement;
    key: T;
  }>;
  onChange: (id?: T[]) => void;
  icon?: ReactElement;
  buttonClass?: string;
  contentClass?: string;
  notFoundText?: string;
};

export const CheckboxFilter = <T extends string>({
  label,
  selected,
  options,
  onChange,
  contentClass,
  notFoundText,
}: Props<T>) => {
  return (
    <Menu as="div" className="relative inline-block w-full text-left">
      {({open}) => (
        <>
          <Menu.Button
            className={`relative min-h-[2.25rem] w-full cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 sm:text-sm sm:leading-6 ${
              open ? 'bg-white ring-1 ring-inset ring-gray-300 text-gray-900' : ''
            } hover:font-semibold hover:bg-white hover:ring-1 hover:ring-inset hover:ring-gray-300 hover:text-gray-900`}
          >
            {label}
            {selected?.length && (
              <span className="ml-2 bg-gray-200 rounded px-1.5 py-0.5">{selected.length}</span>
            )}
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Menu.Button>
          <Menu.Items
            className={cn(
              'relative lg:absolute z-20 mt-1 max-h-64 overflow-auto rounded-md bg-white p-2 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm w-full sm:w-auto sm:min-w-min',
              contentClass
            )}
          >
            {options.length ? (
              options.map(({element, key}) => (
                <div
                  className="m-1.5 flex cursor-pointer items-center space-x-1.5"
                  key={`checkbox-${key}`}
                >
                  <input
                    type="checkbox"
                    checked={!!selected?.includes(key)}
                    onChange={e => {
                      const value = e.target.checked;
                      onChange(
                        value ? [...(selected || []), key] : (selected || []).filter(v => v !== key)
                      );
                    }}
                    id={key}
                    name={key}
                    className="w-4 h-4 border-gray-300 rounded cursor-pointer text-primary-600 focus:ring-primary-600"
                  />
                  <label htmlFor={key} className="w-full cursor-pointer">
                    {element}
                  </label>
                </div>
              ))
            ) : (
              <div>{notFoundText || 'Not Found'}</div>
            )}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};
