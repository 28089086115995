import {ArrowTopRightOnSquareIcon} from '@heroicons/react/24/outline';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {useCallback, useState} from 'react';
import {useNotification} from '../../hooks';
import {IconSpinner} from '../../icons/spinner';

type Props = {
  merchantAccountId: string;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
};
export const ResumeHopLink = ({merchantAccountId, trpc}: Props) => {
  const {showErrorNotification} = useNotification();
  const [isLinkLoading, setIsLinkLoading] = useState(false);
  const {i18n} = useLingui();

  const trpcContext = trpc.useUtils();

  const handleOpenHop = useCallback(async () => {
    setIsLinkLoading(true);
    try {
      const {redirectUrl} = await trpcContext.merchantRegistration.getResumeHopLink.fetch({
        merchantAccountId,
      });
      window.open(redirectUrl, '_blank');
    } catch (e) {
      showErrorNotification(i18n._('Failed to generate a hop link'), (e as Error).message);
    }
    setIsLinkLoading(false);
  }, [merchantAccountId, setIsLinkLoading, showErrorNotification]);

  return (
    <div className="flex items-center justify-end">
      {!isLinkLoading ? (
        <button type="button" onClick={handleOpenHop} title={i18n._('Open hosted onboarding')}>
          <ArrowTopRightOnSquareIcon className="w-5 h-5 cursor-pointer text-primary-600" />
        </button>
      ) : (
        <IconSpinner />
      )}
    </div>
  );
};
