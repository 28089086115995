import {I18n} from '@lingui/core';

export const supportedCountries = ['US', 'CA', 'AU', 'GB', 'NZ'] as const;
export const getCountryOptions = (
  i18n: I18n
): Array<{id: (typeof supportedCountries)[number]; label: string}> => {
  return [
    {
      id: 'US',
      label: i18n._('United States'),
    },
    {
      id: 'CA',
      label: i18n._('Canada'),
    },
    {
      id: 'AU',
      label: i18n._('Australia'),
    },
    {
      id: 'GB',
      label: i18n._('United Kingdom'),
    },
    {
      id: 'NZ',
      label: i18n._('New Zealand'),
    },
  ];
};

export const getPostalCodePatterns = (
  i18n: I18n
): Record<(typeof supportedCountries)[number], {pattern: RegExp; errorMessage: string}> => {
  return {
    // biome-ignore lint/style/useNamingConvention: Suppress naming convention lint for country codes
    US: {
      pattern: /^\d{5}(-\d{4})?$/,
      errorMessage: i18n._('Invalid format. Expected format: 99999 or 99999-9999'),
    },
    // biome-ignore lint/style/useNamingConvention: Suppress naming convention lint for country codes
    CA: {
      pattern: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
      errorMessage: i18n._('Invalid format. Expected format: A9A 9A9 or A9A9A9'),
    },
    // biome-ignore lint/style/useNamingConvention: Suppress naming convention lint for country codes
    AU: {
      pattern: /^\d{4}$/,
      errorMessage: i18n._('Invalid format. Expected format: 9999'),
    },
    // biome-ignore lint/style/useNamingConvention: Suppress naming convention lint for country codes
    GB: {
      pattern:
        /^([A-Za-z][A-Ha-hK-Yk-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/,
      errorMessage: i18n._('Invalid format. Expected format: AA99 9AA or A99 9AA or A9 9AA'),
    },
    // biome-ignore lint/style/useNamingConvention: Suppress naming convention lint for country codes
    NZ: {
      pattern: /^\d{4}$/,
      errorMessage: i18n._('Invalid format. Expected format: 9999'),
    },
  };
};
