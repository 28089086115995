import {Combobox, Transition} from '@headlessui/react';
import {CheckIcon, ChevronDownIcon, MagnifyingGlassIcon} from '@heroicons/react/20/solid';
import {ComponentProps, Fragment, Ref, forwardRef, useCallback, useMemo, useState} from 'react';
import {InputSelectOption} from '../forms/input-select/select-input';
import {Label} from '../layout';
import {cn} from '../utils';

type Props<T extends string> = Omit<ComponentProps<typeof Combobox>, 'value' | 'onChange'> & {
  options: InputSelectOption<T>[];
  value?: T | T[] | null;
  onChange?: (value: T | T[] | null) => void;
  placeholder?: string;
  label?: string;
  className?: string;
  contentClass?: string;
  labelClassName?: string;
  defaultValue?: T | null;
};

function InputSearchSelectFilterComponent<Id extends string>(
  props: Props<Id>,
  ref: Ref<HTMLDivElement>
) {
  const {
    options,
    value,
    onChange,
    placeholder,
    className,
    label,
    labelClassName,
    contentClass,
    defaultValue,
    ...restProps
  } = props;
  const [query, setQuery] = useState('');
  const selectedItem = useMemo<InputSelectOption<Id> | undefined>(
    () => props.options.find(opt => opt.id === value),
    [options, value]
  );

  const filteredOptions =
    query === ''
      ? options
      : options.filter(option =>
          option.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const placeholderLabel = <span className="flex items-center">{placeholder}</span>;

  const handleOnChange = useCallback(
    (newValue: Id) => {
      // prevent onChange triggering on outside click
      if (onChange && newValue !== value) {
        onChange(newValue);
      }
    },
    [onChange, value]
  );
  const isFilterApplied =
    !!selectedItem && (defaultValue ? selectedItem?.id !== defaultValue : true);

  return (
    <Combobox
      as="div"
      ref={ref}
      value={value ?? null}
      {...restProps}
      onChange={handleOnChange}
      className={cn('relative', className)}
      onClick={(e: Event) => e.preventDefault()} // fixes an issue where component fails to close on click inside slideover
    >
      {({open}) => (
        <>
          <Label text={label} className={labelClassName}>
            <Combobox.Button
              className={`relative min-h-[2.25rem] w-full cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 sm:text-sm sm:leading-6 ${
                open ? 'bg-white ring-1 ring-inset ring-gray-300 text-gray-900' : ''
              } hover:font-semibold hover:bg-white hover:ring-1 hover:ring-inset hover:ring-gray-300 hover:text-gray-900 flex items-center`}
            >
              {placeholderLabel}
              {isFilterApplied && (
                <span className="ml-2 bg-gray-200 rounded px-1.5 py-0.5 whitespace-nowrap">1</span>
              )}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </span>
            </Combobox.Button>
          </Label>
          <Transition
            show={open}
            as={Fragment}
            leaveTo="opacity-0"
            leaveFrom="opacity-100"
            leave="transition ease-in duration-100"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options
              static
              className={cn(
                'relative z-20 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg lg:w-60 lg:absolute max-h-56 ring-1 ring-black/5 focus:outline-none sm:text-sm',
                contentClass
              )}
            >
              <div className="flex items-center pl-3 pr-10">
                <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                <Combobox.Input
                  displayValue={_val => query}
                  className="w-full text-sm leading-5 text-left text-gray-900 border-none focus:ring-0 sm:text-sm sm:leading-6"
                  onChange={event => setQuery(event.target.value)}
                />
              </div>
              {filteredOptions.length === 0 && query !== '' ? (
                <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                  Nothing found.
                </div>
              ) : (
                filteredOptions.map(option => (
                  <Combobox.Option
                    key={option.id}
                    className={({active}) =>
                      cn(
                        active
                          ? cn('bg-primary-600 text-white', option.activeClassName)
                          : cn('text-gray-900', option.className),
                        'relative cursor-pointer select-none py-2 pl-3 pr-9 '
                      )
                    }
                    data-te-toggle={!!option.tooltipText && 'tooltip'}
                    title={option.tooltipText}
                    value={option.id}
                    disabled={option.disabled}
                  >
                    {({selected, active}) => (
                      <>
                        <div className="flex items-center ">
                          {option.iconUrl ? (
                            <img
                              src={option.iconUrl}
                              className="w-5 h-5 rounded-full shrink-0"
                              alt="icon"
                            />
                          ) : null}
                          <span
                            className={cn(
                              selected ? 'font-semibold' : 'font-normal',
                              option.iconUrl && 'ml-3',
                              'block truncate'
                            )}
                          >
                            {option.label}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={cn(
                              active ? 'text-white' : 'text-primary-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </>
      )}
    </Combobox>
  );
}

export const InputSearchSelectFilter = forwardRef(InputSearchSelectFilterComponent);

InputSearchSelectFilter.displayName = 'InputSearchSelectFilter';
