import {useStore} from '@/store';
import {useAuth0} from '@auth0/auth0-react';
import {ProtectedLayout} from '@zentact/ui-tailwind';
import LogRocket from 'logrocket';
import ZentactLogo from '../../assets/zentact-logo.svg';
import {navigation, userNavigation} from './navigation';

export function AdminLayout() {
  const {user} = useAuth0();
  const {tenant, pspMerchantAccountName, setPspMerchantAccountName} = useStore();

  if (user?.sub) {
    LogRocket.identify(user.sub, {
      ...(user.name && {name: user.name}),
      ...(user.email && {email: user.email}),
    });
  }

  const selectedPspMerchantAccount = tenant?.pspMerchantAccounts?.find(
    a => a.pspMerchantAccountName === pspMerchantAccountName
  );

  return (
    <ProtectedLayout
      user={user}
      brandName="Zentact"
      brandLogoUrl={ZentactLogo}
      organizationName={tenant.brandConfiguration?.displayName}
      profileSubtitle={
        selectedPspMerchantAccount?.displayName ||
        selectedPspMerchantAccount?.pspMerchantAccountName
      }
      organizationLogoUrl={tenant.brandConfiguration?.logoUrl}
      navItems={navigation({
        enableTerminals: tenant?.features?.enableTerminals,
        showInterchangeReportPage:
          tenant?.features?.enableInterchangeReport?.showInterchangeReportPage,
        disableAdminUserManagement: tenant?.features?.disableAdminUserManagement,
      })}
      profileDropDownItems={userNavigation(
        setPspMerchantAccountName,
        pspMerchantAccountName,
        tenant?.pspMerchantAccounts
      )}
    />
  );
}
