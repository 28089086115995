import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useDefaultSplitConfigurationGroup, useLocale} from '@/hooks';
import {MakeDefaultConfirmation} from '@/pages/configure/transaction-fee-groups/split-configuration-groups-list/make-default-confirmation/make-default-confirmation';
import {AddStoreFormData} from '@/pages/customers/add-store/add-store-form';
import {OrganizationFormData} from '@/pages/customers/organization-form/organization-form';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {
  ErrorCode,
  SplitConfigurationGroupPublicStatus,
  fromMinorUnits,
  isFormattedTrpcError,
  toMinorUnits,
} from '@zentact/common';
import {Breadcrumbs, Button, Typography, useNotification} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {DeepPartial, useForm} from 'react-hook-form';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {
  SplitConfigurationGroupFormData,
  TransactionFeeGroupForm,
  getSplitConfigurationGroupFormSchema,
} from './transaction-fee-group-form';

const getBreadCrumbs = () => [
  {name: t`Configure`, href: RoutePath.CONFIGURE, current: false, disabled: true},
  {name: t`Transaction Fee Groups`, href: RoutePath.TRANSACTION_FEE_GROUPS, current: false},
  {name: t`Create New Fee Group`, href: RoutePath.TRANSACTION_FEE_GROUP_CREATE, current: true},
];

type ReturnToState = {
  inviteOrganizationFormData?: DeepPartial<OrganizationFormData>;
  addStoreFormData?: DeepPartial<AddStoreFormData>;
};

export const TransactionFeeGroupCreatePage = () => {
  const {pspMerchantAccountName, currency} = useStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const {defaultSplitConfigurationGroup} =
    useDefaultSplitConfigurationGroup(pspMerchantAccountName);
  const {returnTo, returnToState} = (location.state ?? {}) as {
    returnTo?: string;
    returnToState?: ReturnToState;
  };

  const sourceFeeGroupId = useMemo(() => searchParams.get('sourceFeeGroupId'), [searchParams]);
  const isDuplicating = !!sourceFeeGroupId;
  const [makeDefaultConfirmationOpen, setMakeDefaultConfirmationOpen] = useState(false);
  const {data: defaultSplitConfigurationData} = trpc.splitConfigurationGroup.byId.useQuery(
    searchParams.get('sourceFeeGroupId') ?? '',
    {enabled: !!sourceFeeGroupId}
  );

  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {locale} = useLocale();

  const form = useForm<SplitConfigurationGroupFormData>({
    resolver: zodResolver(getSplitConfigurationGroupFormSchema(locale, currency)),
    defaultValues: {
      isActive: true,
      chargebackFeesModel: 'TENANT_PAY',
      refundSplitModel: 'REVERSE_PAYMENT_SPLIT',
      refundFeesModel: 'TENANT_PAY',
    },
  });

  useEffect(() => {
    if (!defaultSplitConfigurationData) {
      return;
    }

    const {name, status, refundSplitModel, refundFeesModel, additionalRefundFee, rules} =
      defaultSplitConfigurationData;

    form.reset({
      name: name ? getDuplicateFeeGroupName(name) : '',
      isActive: status === SplitConfigurationGroupPublicStatus.ACTIVE || true,
      rules,
      refundSplitModel: refundSplitModel ?? 'REVERSE_PAYMENT_SPLIT',
      refundFeesModel: refundFeesModel ?? 'TENANT_PAY',
      additionalRefundFee: additionalRefundFee
        ? fromMinorUnits(additionalRefundFee, currency)
        : undefined,
    });
  }, [defaultSplitConfigurationData]);

  const {setError, setFocus, handleSubmit, watch} = form;

  const createSplitConfigurationGroupMutation = trpc.splitConfigurationGroup.create.useMutation({
    onSuccess: created => {
      showSuccessNotification(
        t`Split Configuration Group is created`,
        t`Now you can apply this group to your new merchants`
      );
      navigate(returnTo ? returnTo : RoutePath.TRANSACTION_FEE_GROUPS, {
        state: updateReturnToStateWithCreatedSplitConfigurationGroup(returnToState, created.id),
      });
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      if (errorCode === ErrorCode.SPLIT_CONFIGURATION_GROUP_ALREADY_EXISTS) {
        setError('name', {
          type: 'manual',
          message: t`Fee Group with the same name already exists`,
        });
        setFocus('name');

        return;
      }

      showErrorNotification(
        t`Failed to create split configuration group`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const onSubmit = useCallback(
    (data: SplitConfigurationGroupFormData) => {
      if (data.isDefault && !makeDefaultConfirmationOpen) {
        setMakeDefaultConfirmationOpen(true);
        return;
      }

      createSplitConfigurationGroupMutation.mutate({
        name: data.name,
        isDefault: data.isDefault,
        pspMerchantAccountName,
        status: data.isActive
          ? SplitConfigurationGroupPublicStatus.ACTIVE
          : SplitConfigurationGroupPublicStatus.INACTIVE,
        rules: data.rules,
        chargebackFeesModel: data.chargebackFeesModel,
        additionalChargebackFee: data.additionalChargebackFee
          ? toMinorUnits(data.additionalChargebackFee, currency)
          : 0,
        refundSplitModel: data.refundSplitModel,
        refundFeesModel: data.refundFeesModel,
        additionalRefundFee: data.additionalRefundFee
          ? toMinorUnits(data.additionalRefundFee, currency)
          : 0,
        overrideFeeSectionContent: data.showOverrideFeeInput
          ? data.overrideFeeSectionContent
          : undefined,
      });
    },
    [makeDefaultConfirmationOpen, pspMerchantAccountName]
  );

  const splitConfigurationGroupName = watch('name');

  return (
    <div>
      <Breadcrumbs pages={getBreadCrumbs()} />
      <Typography variant="header-page" className="pt-4">
        {isDuplicating ? <Trans>Duplicate Fee Group</Trans> : <Trans>Create New Fee Group</Trans>}
      </Typography>
      <div className="py-3 text-sm text-gray-700">
        <Trans>
          Set up how to charge your merchants. You can have a default set of fees and create custom
          configurations for different circumstances. You will apply these when adding a new
          merchant. Monthly fees will apply to all merchants onboarded by the 20th day of the month.
        </Trans>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TransactionFeeGroupForm form={form} />
        <footer className="flex justify-end mt-8">
          <Button
            size="lg"
            type="submit"
            className="w-fit"
            disabled={createSplitConfigurationGroupMutation.isLoading}
            isLoading={createSplitConfigurationGroupMutation.isLoading}
          >
            <Trans>Create Fee Group</Trans>
          </Button>
        </footer>
      </form>
      <MakeDefaultConfirmation
        open={makeDefaultConfirmationOpen}
        setOpen={setMakeDefaultConfirmationOpen}
        handleMakeDefault={() => {
          onSubmit(form.getValues());
        }}
        isLoading={createSplitConfigurationGroupMutation.isLoading}
        defaultSplitConfigurationGroup={defaultSplitConfigurationGroup}
        splitConfigurationGroupName={splitConfigurationGroupName}
      />
    </div>
  );
};

const getDuplicateFeeGroupName = (sourceSplitConfigurationGroupName: string) => {
  const copyPattern: RegExp = /\(Copy(?: (\d+))?\)$/;

  // Check if groupName matches the pattern
  const match: RegExpExecArray | null = copyPattern.exec(sourceSplitConfigurationGroupName);

  if (!match) {
    // If pattern is not present, append "(Copy)"
    return `${sourceSplitConfigurationGroupName} (Copy)`;
  }

  // Extract the number part, if present
  const copyNumber: string | undefined = match[1];

  if (copyNumber === undefined) {
    // If no number is present, it's the second copy
    return sourceSplitConfigurationGroupName.replace(' (Copy)', ' (Copy 2)');
  }
  // If a number is present, increment it
  const incrementedNumber: number = parseInt(copyNumber, 10) + 1;
  return sourceSplitConfigurationGroupName.replace(copyPattern, `(Copy ${incrementedNumber})`);
};

const updateReturnToStateWithCreatedSplitConfigurationGroup = (
  returnToState: ReturnToState | undefined,
  splitConfigurationGroupId: string
) => {
  if (returnToState?.inviteOrganizationFormData) {
    const {inviteOrganizationFormData} = returnToState;
    return {
      inviteOrganizationFormData: {
        ...inviteOrganizationFormData,
        splitConfigurationGroupId,
      },
    };
  }
  if (returnToState?.addStoreFormData) {
    const {addStoreFormData} = returnToState;
    return {
      addStoreFormData: {
        ...addStoreFormData,
        splitConfigurationGroupId,
      },
    };
  }
  return returnToState;
};
