import {Listbox, Transition} from '@headlessui/react';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/20/solid';
import {ComponentProps, Fragment, Ref, forwardRef, useMemo} from 'react';
import {TableSortValue} from '../table';
import {cn} from '../utils';

export type SelectInputFilterOption<T extends string> = {
  id: T;
  label: string;
  iconUrl?: string;
  disabled?: boolean;
  className?: string;
  activeClassName?: string;
  tooltipText?: string;
};
export type SelectInputFilterProps<T extends string> = Omit<
  ComponentProps<typeof Listbox>,
  'value' | 'onChange'
> & {
  options: SelectInputFilterOption<T>[];
  value?: T;
  sortValue: TableSortValue<string> | null;
  onChange?: (value: T) => void;
  placeholder?: string;
  className?: string;
  selectedItemLabel?: string;
};

function SelectInputFilterComponent<Id extends string>(
  props: SelectInputFilterProps<Id>,
  ref: Ref<HTMLDivElement>
) {
  const {
    options,
    value,
    sortValue,
    onChange,
    placeholder,
    className,
    selectedItemLabel,
    ...restProps
  } = props;
  const selectedItem = useMemo<SelectInputFilterOption<Id> | undefined>(
    () => options.find(opt => opt.id === value),
    [options, value]
  );

  const placeholderLabel = <span className="flex items-center">{placeholder}</span>;

  const selectedElement = props.multiple ? (
    (Array.isArray(value) &&
      options
        ?.filter(opt => value.includes(opt.id))
        ?.map(({label}) => label)
        .join(',')) ||
    placeholderLabel
  ) : selectedItem ? (
    <span className="flex items-center">
      {selectedItem.iconUrl ? (
        <img src={selectedItem.iconUrl} alt="" className="w-5 h-5 rounded-full shrink-0" />
      ) : null}
      <span className={cn('block truncate', selectedItem.iconUrl && 'ml-3')}>
        {selectedItem.label}
      </span>
    </span>
  ) : (
    placeholderLabel
  );

  return (
    <Listbox
      as="div"
      {...restProps}
      value={value}
      onChange={onChange}
      className={cn('relative', className)}
      ref={ref}
    >
      {({open}) => (
        <>
          <Listbox.Button
            className={`cursor-pointer relative min-h-[2.25rem] font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 sm:text-sm sm:leading-6 ${
              open ? 'bg-white ring-1 ring-inset ring-gray-300 text-gray-900' : ''
            } hover:font-semibold hover:bg-white hover:ring-1 hover:ring-inset hover:ring-gray-300 hover:text-gray-900 `}
          >
            {selectedItemLabel || selectedElement}
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              static
              className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg lg:w-30 max-h-56 ring-1 ring-black/5 focus:outline-none sm:text-sm"
            >
              {options.map(option => (
                <Listbox.Option
                  key={option.id}
                  className={({active}) =>
                    cn(
                      active
                        ? cn('bg-primary-600 text-white', option.activeClassName)
                        : cn('text-gray-900', option.className),
                      'relative cursor-pointer select-none py-2 pl-3'
                    )
                  }
                  data-te-toggle={!!option.tooltipText && 'tooltip'}
                  title={option.tooltipText}
                  value={option.id}
                  disabled={option.disabled}
                >
                  {({selected, active}) => (
                    <>
                      <div className="flex items-center">
                        {option.iconUrl ? (
                          <img
                            src={option.iconUrl}
                            className="w-5 h-5 rounded-full shrink-0"
                            alt="icon"
                          />
                        ) : null}
                        <span
                          className={cn(
                            selected ? 'font-semibold' : 'font-normal',
                            option.iconUrl && 'ml-3',
                            'block truncate'
                          )}
                        >
                          {option.label}
                        </span>
                      </div>

                      {sortValue?.value === 'desc' && sortValue?.columnId === option.id && (
                        <span
                          className={cn(
                            active ? 'text-white' : 'text-primary-600',
                            'absolute inset-y-0 pr-2 right-0 flex items-center '
                          )}
                        >
                          <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      )}
                      {sortValue?.value === 'asc' && sortValue?.columnId === option.id && (
                        <span
                          className={cn(
                            active ? 'text-white' : 'text-primary-600',
                            'absolute inset-y-0 pr-2 right-0 flex items-center'
                          )}
                        >
                          <ChevronUpIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  );
}

export const SelectInputFilter = forwardRef(SelectInputFilterComponent);

SelectInputFilter.displayName = 'SelectInputFilter';
