import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {ChargebackListItemOutput} from '@zentact/api/src/trpc/routers/chargebackRouter';
import {
  CurrencyCode,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatLocaleDate,
} from '@zentact/common';
import {ChargebackDisputeStatus, ChargebackStatus, ChargebackType} from '@zentact/db';
import {DropDownMinimalMenuIcon} from '../../../dropdowns';
import {FlatFillColors, FlatPillWithDot} from '../../../elements';
import {TruncatedText} from '../../../other';
import {TableCheckboxFilter, TableSearchFilter, getTableMeta} from '../../../table';
import {CellCollapsedData} from '../../../table/cells/cell-collapsed-data/cell-collapsed-data';
import {HighlightedText} from '../../highlighted-text';

export const displayChargebackTypeMap: {
  [_ in ChargebackType]: string;
} = {
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  NOTIFICATION_OF_FRAUD: 'Notification Of Fraud',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  REQUEST_FOR_INFORMATION: 'Request For Information',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  CHARGEBACK: 'Chargeback',
};

export const chargebackDisputeStatusToColor: {
  [_ in ChargebackDisputeStatus]: FlatFillColors;
} = {
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  NOT_APPLICABLE: 'gray',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  UNRESPONDED: 'yellow',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  RESPONDED: 'green',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  EXPIRED: 'gray',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  UNDEFENDED: 'yellow',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  PENDING: 'yellow',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  ACCEPTED: 'green',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  LOST: 'red',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  WON: 'green',
};
export const getChargebackDisputeStatusMap = (i18n: I18n) =>
  ({
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    NOT_APPLICABLE: i18n._('Not applicable'),
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    UNRESPONDED: i18n._('Not Responded'),
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    RESPONDED: i18n._('Responded'),
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    EXPIRED: i18n._('Expired'),
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    UNDEFENDED: i18n._('Not Defended'),
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    PENDING: i18n._('Pending'),
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    ACCEPTED: i18n._('Accepted'),
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    LOST: i18n._('Lost'),
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    WON: i18n._('Won'),
  }) satisfies Record<ChargebackDisputeStatus, string>;

export const getChargebackStatusMap = (i18n: I18n) =>
  ({
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    NO_CHARGEBACK: i18n._('Possible Chargeback - Needs Review'),
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    CHARGEBACK: i18n._('Chargeback'),
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    CHARGEBACK_REVERSED: i18n._('Chargeback Reversed'),
    // biome-ignore lint/style/useNamingConvention: DB Type conversion
    SECOND_CHARGEBACK: i18n._('Second Chargeback'),
  }) satisfies Record<ChargebackStatus, string>;

export const chargebackStatusToColor: {
  [_ in ChargebackStatus]: FlatFillColors;
} = {
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  NO_CHARGEBACK: 'gray',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  CHARGEBACK: 'red',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  CHARGEBACK_REVERSED: 'green',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  SECOND_CHARGEBACK: 'red',
};

const columnsHelper = createColumnHelper<ChargebackListItemOutput>();

type Props = {
  locale: LocaleCode;
  i18n: I18n;
  openDetailsPanel: (row: ChargebackListItemOutput) => void;
  showOrganizationColumn?: boolean;
};

export const getChargebackColumns = ({
  locale,
  i18n,
  openDetailsPanel,
  showOrganizationColumn,
}: Props) => {
  const renderDisputeType = (type: ChargebackType) => (
    <span className="py-1 text-xs font-medium">
      {i18n._(displayChargebackTypeMap[type]) ?? i18n._(type)}
    </span>
  );
  return [
    // @ts-ignore fix TS2589: Type instantiation is excessively deep and possibly infinite. error
    columnsHelper.accessor('createdAt', {
      cell: props => formatLocaleDate(props.getValue(), 'shortWithTime'),
      header: () => i18n._('Dispute Date'),
      meta: {
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.display({
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.pspReferenceId as string;
        const textContent = props.row.original.pspReferenceId || '';

        return <HighlightedText text={textContent} highlight={searchString} />;
      },
      id: 'pspReferenceId',
      header: () => i18n._('Dispute ID'),
      meta: {
        filter: {
          filterId: 'pspReferenceId',
          renderFilter: ({...filterProps}) => <TableSearchFilter {...filterProps} />,
        },
        visibleAt: 'md',
      },
      size: TableColumnSize.L,
    }),
    columnsHelper.display({
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.paymentPspReferenceId as string;
        const textContent = props.row.original.payment.pspReferenceId || '';

        return <HighlightedText text={textContent} highlight={searchString} />;
      },
      id: 'paymentPspReferenceId',
      header: () => i18n._('Payment ID'),
      meta: {
        filter: {
          filterId: 'paymentPspReferenceId',
          renderFilter: ({...filterProps}) => <TableSearchFilter {...filterProps} />,
        },
        visibleAt: '2xl',
      },
      size: TableColumnSize.L,
    }),
    columnsHelper.display({
      id: 'merchantName',
      header: () => i18n._('Merchant Account'),
      cell: props => {
        const {breakpoints} = getTableMeta(props.table);
        const merchantName = props.row.original.merchantAccount.businessName;
        const organizationName = props.row.original.organization?.name || 'N/A';

        return (
          <CellCollapsedData
            primary={<TruncatedText text={merchantName} />}
            secondary={<TruncatedText text={organizationName} />}
            hideSecondary={!(showOrganizationColumn && breakpoints.sm)}
          />
        );
      },
      meta: {
        collapseAt: 'sm',
      },
    }),
    columnsHelper.display({
      id: 'storeId',
      cell: props => <TruncatedText text={props.row.original.storeId || i18n._('N/A')} />,
      header: () => i18n._('Store'),
      meta: {
        collapseAt: 'md',
      },
    }),
    columnsHelper.accessor(row => formatAmount(row.amount, locale, row.currency as CurrencyCode), {
      id: 'amount',
      header: () => i18n._('Amount'),
      meta: {
        sort: {
          isSortable: true,
        },
        visibleAt: '2xl',
        align: 'right',
      },
      size: TableColumnSize.XS,
    }),
    columnsHelper.display({
      id: 'status',
      header: () => i18n._('Status'),
      cell: props => {
        const {breakpoints} = getTableMeta(props.table);
        const {status, amount, currency} = props.row.original;

        return (
          <CellCollapsedData
            primary={
              <FlatPillWithDot
                color={chargebackStatusToColor[status] || 'blue'}
                label={getChargebackStatusMap(i18n)[status] ?? status}
              />
            }
            secondary={renderDisputeType(props.row.original.type)}
            tertiary={formatAmount(amount, locale, currency as CurrencyCode)}
            hideTertiary={breakpoints['2xl']}
          />
        );
      },

      meta: {
        align: 'right',
        filter: {
          filterId: 'status',
          renderFilter: ({onChange, filterId, filterValues}) => (
            <TableCheckboxFilter
              filterValues={filterValues}
              filterId={filterId}
              onChange={onChange}
              elements={(
                Object.keys(chargebackStatusToColor) as Array<keyof typeof chargebackStatusToColor>
              ).map(status => ({
                element: (
                  <FlatPillWithDot
                    color={chargebackStatusToColor[status] || 'blue'}
                    label={getChargebackStatusMap(i18n)[status] ?? status}
                  />
                ),
                key: status,
              }))}
            />
          ),
        },
      },
      size: 180,
    }),
    columnsHelper.display({
      id: 'actions',
      cell: props => (
        <div className="flex justify-center">
          <DropDownMinimalMenuIcon
            items={[
              {name: i18n._('View Details'), onClick: () => openDetailsPanel(props.row.original)},
            ]}
          />
        </div>
      ),
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
