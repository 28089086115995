import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useLocale} from '@/hooks';
import {useStore} from '@/store';
import {
  BanknotesIcon,
  BriefcaseIcon,
  CalendarIcon,
  ChevronDownIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/20/solid';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {TenantAccountActivityDetailsItem} from '@zentact/api/src/trpc/routers/financeRouter';
import {
  CurrencyCode,
  TenantCategoryArray,
  accountActivitySearchParamsSchema,
  formatAccounting,
  formatLocaleDate,
} from '@zentact/common';
import {
  AccountActivityDetailsSidePanel,
  AccountActivityDetailsTable,
  ActivityDetailsFilters,
  Breadcrumbs,
  Button,
  ResetTableFiltersButton,
  SlideOverWithBrandedHeader,
  TableSortValue,
  Typography,
  fetchFullListFromPaginatedEndpoint,
  useNotification,
  useToggle,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';
import {useCallback, useEffect, useState} from 'react';
import {exportToCsvTenantAccountActivityDetails} from './csv-export';

export const TenantAccountActivityDetails = () => {
  const {currency} = useStore();
  const {locale} = useLocale();

  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(
    accountActivitySearchParamsSchema
  );

  const batchId = typedSearchParams?.batchId;
  const startDate = batchId
    ? null
    : typedSearchParams?.startDate || DateTime.now().startOf('month').toJSDate();
  const endDate = batchId
    ? null
    : typedSearchParams?.endDate || DateTime.now().endOf('month').toJSDate();
  const category = typedSearchParams?.category;

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams]);

  const [isSidePanelOpen, openSidePanel, closeSidePanel] = useToggle(false);
  const [accountActivityDetailsRow, setAccountActivityDetailsRow] =
    useState<TenantAccountActivityDetailsItem | null>(null);

  const {showSuccessNotification, showErrorNotification} = useNotification();

  const getBreadCrumbs = () => [
    {name: t`Finance`, href: RoutePath.FINANCE, disabled: true},
    {name: t`Account Activity`, href: RoutePath.FINANCE_ACCOUNT_ACTIVITY, current: true},
  ];

  const [sort, setSort] = useState<TableSortValue<string>>({
    columnId: 'creationDate',
    value: 'desc',
  });

  const {data: balances} = trpc.finance.getTenantBalances.useQuery(undefined, {
    keepPreviousData: true,
    cacheTime: 60 * 60 * 1000, // cache for 60 minutes
  });

  const accountActivityDetailsFilters = {
    ...(sort?.columnId && sort.value && {orderBy: {columnId: sort.columnId, value: sort.value}}),
    where: {
      ...(category && {category}),
      ...(batchId && {batchId}),
      ...(currency && {currency}),
      ...(startDate && {
        transactionDateFrom: DateTime.fromJSDate(startDate).startOf('day').toJSDate(),
        transactionDateTo: endDate
          ? DateTime.fromJSDate(endDate).endOf('day').toJSDate()
          : DateTime.fromJSDate(startDate).endOf('day').toJSDate(),
      }),
    },
  };

  const accountActivityDetails = trpc.finance.getTenantAccountActivityDetails.useQuery(
    {
      ...accountActivityDetailsFilters,
      ...pagination,
    },
    {keepPreviousData: true}
  );

  const handleDateChange = useCallback(
    (dateRange: {startDate: Date; endDate: Date}) => {
      setTypedSearchParams({batchId: undefined, ...dateRange});
    },
    [setTypedSearchParams]
  );

  const handleOpenSidePanel = useCallback(
    (row: TenantAccountActivityDetailsItem) => {
      setAccountActivityDetailsRow(row);
      openSidePanel();
    },
    [openSidePanel, setAccountActivityDetailsRow]
  );

  const {i18n} = useLingui();
  const trpcContext = trpc.useUtils();

  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullAccountActivityDetails = await fetchFullListFromPaginatedEndpoint(
        trpcContext.finance.getTenantAccountActivityDetails,
        accountActivityDetailsFilters
      );
      exportToCsvTenantAccountActivityDetails(fullAccountActivityDetails.rows, i18n);
      showSuccessNotification(t`Account activity .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Account activity .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [
    setCsvLoading,
    accountActivityDetailsFilters,
    exportToCsvTenantAccountActivityDetails,
    sort,
    trpcContext,
  ]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex flex-wrap items-center justify-between pt-4">
        <div className="flex gap-4 font-normal">
          <Typography variant="header-page">
            {startDate && endDate ? (
              <>
                <Trans>Account Activity For</Trans> {formatLocaleDate(startDate, 'short')}
                {' - '}
                {formatLocaleDate(endDate, 'short')}
              </>
            ) : (
              <Trans>Account Activity</Trans>
            )}
          </Typography>
        </div>
        <div className="flex items-center gap-4">
          <div className="hidden lg:block">
            <ActivityDetailsFilters
              typedSearchParams={typedSearchParams}
              setTypedSearchParams={setTypedSearchParams}
              dateValue={
                startDate
                  ? {
                      startDate,
                      endDate,
                    }
                  : null
              }
              category={category}
              categoryList={TenantCategoryArray}
              handleDateChange={handleDateChange}
            />
          </div>
          <SlideOverWithBrandedHeader
            isOpen={isFiltersOpen}
            title={'Filters'}
            closeHandler={() => setIsFiltersOpen(false)}
            panelClassName="w-screen pointer-events-auto lg:max-w-md"
          >
            <ActivityDetailsFilters
              typedSearchParams={typedSearchParams}
              setTypedSearchParams={setTypedSearchParams}
              dateValue={
                startDate
                  ? {
                      startDate,
                      endDate,
                    }
                  : null
              }
              category={category}
              categoryList={TenantCategoryArray}
              handleDateChange={handleDateChange}
            />
          </SlideOverWithBrandedHeader>
          <div className="flex items-center justify-between w-full gap-4">
            <Button
              type="button"
              size="sm"
              onClick={() => setIsFiltersOpen(true)}
              className="lg:hidden w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
            >
              <Trans>Filter</Trans>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </span>
            </Button>
            <div className="lg:hidden">
              <ResetTableFiltersButton
                ignoreKeys={[
                  'endDate', // ignore endDate to show date range as a single applied date filter
                ]}
                defaultFilters={{}}
                activeFilters={typedSearchParams}
                setFilters={setTypedSearchParams}
              />
            </div>
            <Button
              type="button"
              variant="primary"
              size="md"
              className="w-fit"
              isLoading={isCsvLoading}
              onClick={handleCsvExport}
              disabled={
                !accountActivityDetails.data || accountActivityDetails.data.rows.length === 0
              }
            >
              <Trans>Export to CSV</Trans>
            </Button>
          </div>
        </div>
      </div>
      {balances
        ?.filter(b => b.currency === currency)
        .map(balance => (
          <div
            key={balance.currency}
            className="flex flex-col mt-1 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-2"
          >
            {/* Only display currency if there are multiple balances */}
            {balances.length > 1 && (
              <div className="flex items-center mt-2 text-sm text-gray-900">{balance.currency}</div>
            )}
            <div className="flex items-center mt-2 text-sm text-gray-900">
              <CurrencyDollarIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                aria-hidden="true"
              />
              <Trans>
                Balance:{' '}
                {formatAccounting(balance.balance, locale, balance.currency as CurrencyCode)}
              </Trans>
            </div>
            <div className="flex items-center mt-2 text-sm text-gray-900">
              <BanknotesIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                aria-hidden="true"
              />
              <Trans>
                Available:{' '}
                {formatAccounting(balance.available, locale, balance.currency as CurrencyCode)}
              </Trans>
            </div>
            <div className="flex items-center mt-2 text-sm text-gray-900">
              <BriefcaseIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                aria-hidden="true"
              />
              <Trans>
                Pending:{' '}
                {formatAccounting(balance.pending || 0, locale, balance.currency as CurrencyCode)}
              </Trans>
            </div>
            <div className="flex items-center mt-2 text-sm text-gray-900">
              <CalendarIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                aria-hidden="true"
              />
              <Trans>
                Reserved:{' '}
                {formatAccounting(balance.reserved, locale, balance.currency as CurrencyCode)}
              </Trans>
            </div>
          </div>
        ))}
      <div className="mt-4">
        <AccountActivityDetailsTable
          accountActivityDetails={accountActivityDetails.data}
          filters={typedSearchParams || {}}
          pagination={pagination}
          onPaginationChange={setPagination}
          sort={sort}
          setSort={setSort}
          paymentDetailsPath={RoutePath.PAYMENT_DETAILS}
          activityDetailsPath={RoutePath.FINANCE_ACCOUNT_ACTIVITY}
          onRowClick={handleOpenSidePanel}
          isLoading={
            accountActivityDetails.isLoading ||
            (accountActivityDetails.isRefetching && accountActivityDetails.isPreviousData)
          }
        />
      </div>
      <AccountActivityDetailsSidePanel
        isOpen={isSidePanelOpen}
        onCancel={closeSidePanel}
        row={accountActivityDetailsRow}
        paymentDetailsPath={RoutePath.PAYMENT_DETAILS}
        activityDetailsPath={RoutePath.FINANCE_ACCOUNT_ACTIVITY}
      />
    </div>
  );
};
