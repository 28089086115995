import {useState} from 'react';
import {Link} from 'react-router-dom';
import {cn} from '../../utils';

type Props = {
  className?: string;
  orgLogoUrl?: string | null;
  orgName?: string;
};

export const LogoOrganization = ({className, orgLogoUrl, orgName}: Props) => {
  const [imageError, setImageError] = useState(false);

  return (
    <div className={cn('flex lg:flex-1', className)}>
      <Link to="/">
        {orgLogoUrl && !imageError ? (
          <img
            src={`${orgLogoUrl}?max-h=110&max-w=480&fit=fill&fm=png`}
            className="max-h-10 max-w-56"
            alt={orgName}
            onError={() => setImageError(true)}
          />
        ) : (
          <span>{orgName}</span>
        )}
      </Link>
    </div>
  );
};
