import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {allowedTimezoneList, featuresSchema} from '@zentact/common';
import {
  Button,
  InputSearchSelect,
  InputText,
  InputToggle,
  Label,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';

type Features = z.infer<typeof featuresSchema>;

const unwrapOptional = (schema: unknown) => {
  return schema instanceof z.ZodOptional ? schema.unwrap() : schema;
};

export const DeveloperSidebar = ({onUpdate}: {onUpdate: () => void}) => {
  const {tenant, tenantRefetch} = useStore();
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
  } = useForm<Features>({
    defaultValues: tenant?.features,
    resolver: zodResolver(featuresSchema),
  });

  const updateFeatures = trpc.developer.updateFeatures.useMutation({
    onError: error => {
      showErrorNotification(error.message);
    },
    onSuccess: async () => {
      await tenantRefetch();
      onUpdate();
      showSuccessNotification('Features updated');
    },
  });

  const timezoneOptions = [...allowedTimezoneList].map(timeZone => {
    const timeZoneData = Intl.DateTimeFormat(undefined, {
      timeZone,
      timeZoneName: 'short',
    })
      .formatToParts(new Date())
      .find(part => part.type === 'timeZoneName');

    const utcOffset = timeZoneData?.value || 'Unknown';

    return {
      id: timeZone,
      label: `(${utcOffset}) ${timeZone}`,
    };
  });

  const onSubmit = (data: Features) => {
    const {staticMerchantTimeZone, ...rest} = data;
    updateFeatures.mutate({
      staticMerchantTimeZone:
        staticMerchantTimeZone === 'not selected' ? undefined : staticMerchantTimeZone,
      ...rest,
    });
  };

  const renderField = ([key, value]: [string, unknown], path: string[] = []) => {
    const unwrappedValue = unwrapOptional(value);
    const name = path.length ? `${path.join('.')}.${key}` : key;

    if (unwrappedValue instanceof z.ZodBoolean) {
      return (
        <div key={name} className="my-2">
          <InputToggle toggleSize="md" {...register(name as keyof Features)} label={key} />
        </div>
      );
    }
    if (key === 'staticMerchantTimeZone') {
      return (
        <div className="sm:col-span-3">
          <Controller
            name="staticMerchantTimeZone"
            control={control}
            render={({field}) => (
              <InputSearchSelect
                {...field}
                className="mb-2"
                label={'Time Zone'}
                options={[
                  {
                    id: 'not selected',
                    label: 'Not selected',
                  },
                  ...timezoneOptions,
                ]}
                placeholder={'Please select the value'}
              />
            )}
          />
          <ValidationError isVisible={Boolean(errors.staticMerchantTimeZone)}>
            {errors.staticMerchantTimeZone?.message}
          </ValidationError>
        </div>
      );
    }
    if (unwrappedValue instanceof z.ZodString) {
      return (
        <div key={name} className="my-2">
          <Label text={key} className="text-sm">
            <InputText {...register(name as keyof Features)} />
          </Label>
        </div>
      );
    }
    if (unwrappedValue instanceof z.ZodObject) {
      return (
        <div key={name} className="my-2">
          <fieldset>
            <legend>{key}</legend>
            {Object.entries(unwrappedValue.shape).map(([nestedKey, nestedValue]) =>
              renderField([nestedKey, nestedValue], [...path, key])
            )}
          </fieldset>
        </div>
      );
    }
    return null;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Features:</h2>
      {Object.entries(featuresSchema.shape).map(([key, value]) => renderField([key, value]))}
      <div className="flex justify-end mt-10">
        <Button size="lg" type="submit" className="w-fit" isLoading={updateFeatures.isLoading}>
          Update
        </Button>
      </div>
    </form>
  );
};
