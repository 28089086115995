import {useLingui} from '@lingui/react';
import {Dispatch} from 'react';
import {TableSort} from '../table/types';
import {SelectInputFilter} from './SelectInputFilter';

const nextSort: Record<string, TableSort> = {
  asc: null,
  desc: 'asc',
  null: 'desc',
};

type Props<SortKey extends string> = {
  options: Array<{
    label: string;
    id: SortKey;
  }>;
  sort: {columnId: SortKey; value: TableSort} | null;
  setSort: Dispatch<{columnId: SortKey; value: TableSort}>;
};
export const TableListSort = <SortKey extends string>({options, sort, setSort}: Props<SortKey>) => {
  const i18n = useLingui();

  return (
    <div className="w-32 z-20">
      <SelectInputFilter
        options={options}
        sortValue={sort}
        onChange={columnId => {
          const currentSort = sort?.value && sort?.columnId === columnId ? sort.value : null;
          setSort({
            columnId: columnId as SortKey,
            value: nextSort[String(currentSort)] ?? null,
          });
        }}
        placeholder={i18n._('Sort')}
      />
    </div>
  );
};
