import {
  Cell,
  ColumnDef,
  OnChangeFn,
  PaginationState,
  Row,
  Table,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, {useCallback, useEffect, useState} from 'react';
import {FilterOnChange, TablePagination, TableSortValue} from '.';
import {Loading} from '..';
import {BreakPoints, useTailwindBreakpoints} from '../hooks';
import {cn} from '../utils';
import {TableHeaderCellWrapper} from './table-header-cell-wrapper';

type ExtractFiltersOnChangeArgs<TItem, TMeta, T extends ColumnDef<TItem, TMeta>[]> = Parameters<
  // @ts-ignore TODO
  Parameters<NonNullable<NonNullable<T[number]['meta']>['filter']>['renderFilter']>[0]['onChange']
>[0];

// biome-ignore lint/suspicious/noExplicitAny: TODO
type TMetaFromColumns<TColumns> = TColumns extends ColumnDef<any, infer TMeta>[] ? TMeta : never;

export const TableWrapper = <
  TItem,
  TFilters extends Record<string, unknown> = Record<string, unknown>,
  // biome-ignore lint/suspicious/noExplicitAny: TODO
  TColumns extends ColumnDef<TItem, TMetaFromColumns<TColumns>>[] = ColumnDef<TItem, any>[],
>({
  data,
  columns,
  showFooter = false,
  className,
  filterValues,
  sortValue,
  onFilterChange,
  onSortChange,
  noDataMessage,
  isLoading,
  pagination,
  pageCount,
  onPaginationChange,
  onRowClick,
  isRowClickable,
  excludeLastColumnFromRowClick,
}: {
  data: TItem[];
  columns: TColumns;
  showFooter?: boolean;
  className?: string;
  filterValues?: TFilters;
  onFilterChange?: (
    args: ExtractFiltersOnChangeArgs<TItem, TMetaFromColumns<TColumns>, TColumns> & {
      table: Table<TItem>;
    }
  ) => void;
  sortValue?: TableSortValue<string> | null;
  onSortChange?: (args: TableSortValue<string>) => void;
  noDataMessage?: React.ReactNode;
  isLoading?: boolean;
  pagination?: PaginationState;
  pageCount?: number;
  onPaginationChange?:
    | React.Dispatch<React.SetStateAction<PaginationState>>
    | OnChangeFn<PaginationState>;
  onRowClick?: (row: TItem) => void;
  isRowClickable?: (row: TItem) => boolean;
  excludeLastColumnFromRowClick?: boolean;
}) => {
  const {breakpoints} = useTailwindBreakpoints();
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  // is the column shown inside other column {[id]: boolean}
  const [isColumnShownAsCollapsed, setIsColumnShownAsCollapsed] = useState<VisibilityState>({});

  const getCombinedCells = (row: Row<TItem>) => {
    const allCells = [...row.getAllCells()];
    const combined = allCells
      .reverse() // step from right to left
      .reduce(
        (
          acc: {
            combinedCells: Array<Array<Cell<TItem, unknown>>>;
            cellsToCollapse: Array<Cell<TItem, unknown>>;
          },
          cell
        ) => {
          if (cell.column.getIsVisible()) {
            acc.combinedCells.push([cell, ...acc.cellsToCollapse.reverse()]);
            acc.cellsToCollapse = [];
            return acc;
          }
          if (isColumnShownAsCollapsed[cell.column.id]) {
            // if not visible and collapseAt specified then merge it with the next visible cell
            acc.cellsToCollapse.push(cell);
          }
          return acc;
        },
        {combinedCells: [], cellsToCollapse: []}
      )
      .combinedCells.reverse(); // return to origin order
    return combined;
  };

  const table = useReactTable<TItem>({
    columns,
    data,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      pagination,
    },
    onColumnVisibilityChange: setColumnVisibility,
    manualPagination: true,
    onPaginationChange,
    meta: {
      breakpoints,
      filterValues,
    },
  });

  // column visibility is based on column.meta.visibleAt
  useEffect(() => {
    const columns = table.getAllLeafColumns();

    const newVisibility = columns.map(column => {
      const visibleAt: BreakPoints =
        column.columnDef.meta?.collapseAt ?? column.columnDef.meta?.visibleAt ?? 'mobile';
      const shouldBeVisible = breakpoints[visibleAt];

      return [column.id, shouldBeVisible];
    });
    table.setColumnVisibility(Object.fromEntries(newVisibility));

    const newColumnCollapseVisibility = columns.map(column => {
      const visibleAt: BreakPoints = column.columnDef.meta?.visibleAt ?? 'mobile';
      const collapseAt: BreakPoints | undefined = column.columnDef.meta?.collapseAt;
      const shouldBeCollapsed = collapseAt && !breakpoints[collapseAt] && breakpoints[visibleAt];

      return [column.id, shouldBeCollapsed];
    });
    setIsColumnShownAsCollapsed(Object.fromEntries(newColumnCollapseVisibility));
  }, [breakpoints]);

  const handleFilterChange = useCallback<FilterOnChange>(
    ({id, value}) => {
      if (onFilterChange) onFilterChange({id, value, table});
    },
    [onFilterChange, table]
  );

  const tableRows = table.getRowModel().rows;

  const isRowClickableEnabled = (row: {original: TItem}) => {
    return isRowClickable ? isRowClickable(row.original) : true;
  };

  return (
    <div className="lg:overflow-visible z-0">
      <table
        className={cn(
          'min-w-full border-collapse divide-y divide-gray-300 rounded-lg shadow',
          className
        )}
      >
        <thead className="z-10 bg-gray-50 sticky top-16">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHeaderCellWrapper
                    key={header.id}
                    header={header}
                    table={table}
                    onFilterChange={handleFilterChange}
                    filterValues={filterValues}
                    onSortChange={onSortChange}
                    sortValue={sortValue}
                  />
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody className="bg-white divide-y divide-gray-200">
          {!data.length && noDataMessage && !isLoading && (
            <tr>
              <td colSpan={columns.length} className="text-center h-80">
                {noDataMessage}
              </td>
            </tr>
          )}
          {isLoading ? (
            <tr>
              <td colSpan={columns.length} className="items-center h-80">
                <div className="flex items-center justify-center">
                  <Loading mode="inline" />
                </div>
              </td>
            </tr>
          ) : (
            tableRows.map(row => (
              <tr
                key={row.id}
                className={cn(
                  'hover:bg-gray-50',
                  onRowClick && isRowClickableEnabled(row) && 'cursor-pointer'
                )}
              >
                {getCombinedCells(row).map((combinedCell, index, arr) => {
                  const {
                    align = 'left',
                    columnCellClassName,
                    rowCellClassName,
                    collapseRowStyle,
                  } = combinedCell[0]?.column.columnDef.meta ?? {};

                  return (
                    // biome-ignore lint/a11y/useKeyWithClickEvents: TODO
                    <td
                      // biome-ignore lint/suspicious/noArrayIndexKey: There isn't a unique id available
                      key={index}
                      style={{width: combinedCell[0]?.column.getSize()}}
                      className={cn(
                        'whitespace-nowrap py-4 text-sm leading-5',
                        index === arr.length - 1 && 'pr-3',
                        align === 'left' ? 'pl-4 pr-2 text-left' : 'pr-4 pl-2 text-right',
                        columnCellClassName,
                        rowCellClassName,
                        isRowClickableEnabled(row) &&
                          excludeLastColumnFromRowClick &&
                          'cursor-pointer'
                      )}
                      onClick={() => {
                        const isLastColumn = index === arr.length - 1;
                        const isClickable =
                          onRowClick &&
                          isRowClickableEnabled(row) &&
                          !(excludeLastColumnFromRowClick && isLastColumn);

                        if (isClickable) {
                          onRowClick(row.original);
                        }
                      }}
                    >
                      <div
                        className={cn(
                          'flex',
                          !collapseRowStyle ? 'flex-col gap-y-2' : 'gap-x-1',
                          align === 'right' && 'justify-end'
                        )}
                      >
                        {combinedCell.map((cell, id) => (
                          <div
                            // biome-ignore lint/suspicious/noArrayIndexKey: Unique id is not available
                            key={id}
                            className={`${
                              index === 0 && id === 0
                                ? 'font-medium text-gray-900'
                                : 'text-gray-500'
                            }`}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </div>
                        ))}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
        {showFooter && (
          <tfoot className="bg-gray-50">
            {table.getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className={cn(
                      'py-3.5 pl-4 pr-3 text-left text-sm',
                      header.column.columnDef.meta?.columnCellClassName,
                      header.column.columnDef.meta?.footerCellClassName
                    )}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
      {pagination && (
        <div className="pt-8">
          <TablePagination table={table} />
        </div>
      )}
    </div>
  );
};
