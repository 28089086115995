import {useState} from 'react';
import {Link} from 'react-router-dom';
import {NavigationItem, SidebarNavigation} from './sidebar-navigation';

type Props = {
  logoUrl?: string | null;
  displayName?: string;
  navItems: NavigationItem[];
  onNavigate: () => void;
};

export const SidebarContent = ({logoUrl, displayName, navItems, onNavigate}: Props) => {
  const [imageError, setImageError] = useState(false);

  return (
    <div className="flex flex-col px-6 pb-4 overflow-y-auto bg-primary-100 grow">
      <div className="flex items-center shrink-0">
        <div className="flex justify-start mt-6 lg:flex-1">
          <Link to="/" onClick={onNavigate}>
            {logoUrl && !imageError ? (
              <img
                src={`${logoUrl}?max-h=110&max-w=480&fit=fill&fm=png`}
                className="max-w-60 max-h-14"
                alt={displayName}
                onError={() => setImageError(true)}
              />
            ) : (
              <span>{displayName}</span>
            )}
          </Link>
        </div>
      </div>
      <SidebarNavigation items={navItems} onNavigate={onNavigate} />
    </div>
  );
};
