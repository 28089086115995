import {PaymentMethodSetupInfo} from '@adyen/api-library/lib/src/typings/management/paymentMethodSetupInfo';

export enum SplitConfigurationGroupPublicStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum TenantIntervalFeeGroupPublicStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export const ExtendedPaymentMethodEnum = {
  // biome-ignore lint/style/useNamingConvention: <explanation>
  ANY: 'ANY',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  ACH: 'ach',
  ...PaymentMethodSetupInfo.TypeEnum,
} as const;

export type ExtendedPaymentMethodType =
  (typeof ExtendedPaymentMethodEnum)[keyof typeof ExtendedPaymentMethodEnum];

export const ExtendedPaymentMethodEnumDisplayMap: Record<ExtendedPaymentMethodType, string> = {
  [ExtendedPaymentMethodEnum.ANY]: 'ANY',
  [ExtendedPaymentMethodEnum.ACH]: 'ACH US Direct Debit',
  [ExtendedPaymentMethodEnum.Afterpaytouch]: 'Afterpay Touch',
  [ExtendedPaymentMethodEnum.Alipay]: 'Alipay',
  [ExtendedPaymentMethodEnum.AlipayHk]: 'Alipay Hong Kong',
  [ExtendedPaymentMethodEnum.Amex]: 'American Express',
  [ExtendedPaymentMethodEnum.Applepay]: 'Apple Pay',
  [ExtendedPaymentMethodEnum.Bcmc]: 'Bancontact',
  [ExtendedPaymentMethodEnum.Blik]: 'BLIK',
  [ExtendedPaymentMethodEnum.Cartebancaire]: 'Carte Bancaire',
  [ExtendedPaymentMethodEnum.Clearpay]: 'Clearpay',
  [ExtendedPaymentMethodEnum.Clicktopay]: 'Click to Pay',
  [ExtendedPaymentMethodEnum.Cup]: 'China UnionPay (CUP)',
  [ExtendedPaymentMethodEnum.Diners]: 'Diners Club International',
  [ExtendedPaymentMethodEnum.DirectdebitGb]: 'Direct Debit GB',
  [ExtendedPaymentMethodEnum.Discover]: 'Discover',
  [ExtendedPaymentMethodEnum.EbankingFi]: 'E-Banking Finland',
  [ExtendedPaymentMethodEnum.EftposAustralia]: 'EFTPOS Australia',
  [ExtendedPaymentMethodEnum.Elo]: 'Elo',
  [ExtendedPaymentMethodEnum.Elocredit]: 'Elo Credit',
  [ExtendedPaymentMethodEnum.Elodebit]: 'Elo Debit',
  [ExtendedPaymentMethodEnum.Girocard]: 'Girocard',
  [ExtendedPaymentMethodEnum.Googlepay]: 'Google Pay',
  [ExtendedPaymentMethodEnum.Hiper]: 'Hiper',
  [ExtendedPaymentMethodEnum.Hipercard]: 'Hipercard',
  [ExtendedPaymentMethodEnum.Ideal]: 'iDEAL',
  [ExtendedPaymentMethodEnum.Jcb]: 'JCB',
  [ExtendedPaymentMethodEnum.Klarna]: 'Klarna',
  [ExtendedPaymentMethodEnum.KlarnaAccount]: 'Klarna Account',
  [ExtendedPaymentMethodEnum.KlarnaPaynow]: 'Klarna Pay Now',
  [ExtendedPaymentMethodEnum.Maestro]: 'Maestro',
  [ExtendedPaymentMethodEnum.Mbway]: 'MB WAY',
  [ExtendedPaymentMethodEnum.Mc]: 'Mastercard',
  [ExtendedPaymentMethodEnum.Mcdebit]: 'Mastercard Debit',
  [ExtendedPaymentMethodEnum.MealVoucherFr]: 'Meal Voucher (France)',
  [ExtendedPaymentMethodEnum.Mobilepay]: 'MobilePay',
  [ExtendedPaymentMethodEnum.Multibanco]: 'Multibanco',
  [ExtendedPaymentMethodEnum.OnlineBankingPl]: 'Online Banking Poland',
  [ExtendedPaymentMethodEnum.Paybybank]: 'Pay by Bank',
  [ExtendedPaymentMethodEnum.Paypal]: 'PayPal',
  [ExtendedPaymentMethodEnum.Payshop]: 'Payshop',
  [ExtendedPaymentMethodEnum.Swish]: 'Swish',
  [ExtendedPaymentMethodEnum.Trustly]: 'Trustly',
  [ExtendedPaymentMethodEnum.Twint]: 'TWINT',
  [ExtendedPaymentMethodEnum.TwintPos]: 'TWINT (POS)',
  [ExtendedPaymentMethodEnum.Vipps]: 'Vipps',
  [ExtendedPaymentMethodEnum.Visa]: 'Visa',
  [ExtendedPaymentMethodEnum.Visadebit]: 'Visa Debit',
  [ExtendedPaymentMethodEnum.Vpay]: 'VPay',
  [ExtendedPaymentMethodEnum.Wechatpay]: 'WeChat Pay',
  [ExtendedPaymentMethodEnum.WechatpayPos]: 'WeChat Pay (POS)',
  [ExtendedPaymentMethodEnum.InteracCard]: 'Interac',
  [ExtendedPaymentMethodEnum.Accel]: 'Accel Payments Network',
  [ExtendedPaymentMethodEnum.Affirm]: 'Affirm',
  [ExtendedPaymentMethodEnum.Alelo]: 'Alelo Voucher',
  [ExtendedPaymentMethodEnum.BaneseCard]: 'Banese Card',
  [ExtendedPaymentMethodEnum.BaneseCardCredit]: 'Banese Card Credit',
  [ExtendedPaymentMethodEnum.BaneseCardDebit]: 'Banese Card Debit',
  [ExtendedPaymentMethodEnum.BaneseCardPrepaid]: 'Banese Card Prepaid',
  [ExtendedPaymentMethodEnum.Credtodos]: 'Credtodos',
  [ExtendedPaymentMethodEnum.CredtodosPrivateCredit]: 'Credtodos Private Credit',
  [ExtendedPaymentMethodEnum.CredtodosPrivateDebit]: 'Credtodos Private Debit',
  [ExtendedPaymentMethodEnum.Nyce]: 'NYCE Payments Network',
  [ExtendedPaymentMethodEnum.Payme]: 'PayMe',
  [ExtendedPaymentMethodEnum.PaymePos]: 'PayMe (POS)',
  [ExtendedPaymentMethodEnum.Paynow]: 'PayNow',
  [ExtendedPaymentMethodEnum.PaynowPos]: 'PayNow (POS)',
  [ExtendedPaymentMethodEnum.Pulse]: 'PULSE Network',
  [ExtendedPaymentMethodEnum.Sodexo]: 'Sodexo Voucher',
  [ExtendedPaymentMethodEnum.Star]: 'STAR Payments Network',
  [ExtendedPaymentMethodEnum.Ticket]: 'Ticket Restaurant',
  [ExtendedPaymentMethodEnum.TodoGiftcard]: 'Todo Gift Card',
  [ExtendedPaymentMethodEnum.UpBrazilCredit]: 'Up Brazil Credit',
  [ExtendedPaymentMethodEnum.ValeRefeicao]: 'Vale Refeição',
  [ExtendedPaymentMethodEnum.ValeRefeicaoPrepaid]: 'Vale Refeição Prepaid',
};

export enum ShopperInteractionPublicEnum {
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Any = 'ANY',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Ecommerce = 'Ecommerce',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  ContAuth = 'ContAuth',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Moto = 'Moto',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  Pos = 'POS',
}
