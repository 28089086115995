import {t} from '@lingui/macro';
import {InputText, Label, ValidationError} from '@zentact/ui-tailwind';
import {UseFormReturn} from 'react-hook-form';
import z from 'zod';

export const addIndustryCodeFormSchema = () =>
  z.object({
    code: z
      .string()
      .min(2, t`Code must be at least 2 characters`)
      .max(20, t`Code must be at most 20 characters`),
  });

export type ApiIndustryCodeFormData = z.infer<ReturnType<typeof addIndustryCodeFormSchema>>;

type MerchantAccountFormProps = {
  form: UseFormReturn<ApiIndustryCodeFormData>;
  initialValues?: {email?: string};
  children?: JSX.Element;
};

export const AddIndustryCodeForm = ({form, children}: MerchantAccountFormProps) => {
  const {
    register,
    formState: {errors},
  } = form;

  return (
    <div className="flex flex-col gap-3">
      <Label text={t`Code`}>
        <InputText {...register('code', {required: true})} hasError={Boolean(errors.code)} />
        <ValidationError isVisible={Boolean(errors.code)}>{errors.code?.message}</ValidationError>
      </Label>
      {children}
    </div>
  );
};
