import {useLocale} from '@/hooks/useLocale';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {
  CurrencyCode,
  FEE_MIN_AND_MAX_RESTRICTIONS,
  LocaleCode,
  allShopperInteractions,
  formatAmount,
  fromMinorUnits,
  toMinorUnits,
} from '@zentact/common';
import {ExtendedPaymentMethodEnum} from '@zentact/common';
import {
  Button,
  InputRadio,
  InputText,
  InputToggle,
  Label,
  Section,
  Typography,
  ValidationError,
  useDrawerState,
} from '@zentact/ui-tailwind';
import {useMemo} from 'react';
import {UseFormReturn, useFieldArray} from 'react-hook-form';
import z from 'zod';
import {AddRuleFormData} from './add-rule-form';
import {AddRulePanel} from './add-rule-panel';
import {OverridePricingSection} from './override-pricing-section';
import {PaymentRulesList} from './payment-rules';

export const getSplitPercentageSchema = (tenantCurrency: CurrencyCode) =>
  z.coerce
    .number({
      // biome-ignore lint/style/useNamingConvention: ZOD conventions
      invalid_type_error: t`Percentage must be a number`,
      // biome-ignore lint/style/useNamingConvention: ZOD conventions
      required_error: t`Fee is required`,
    })
    .min(
      fromMinorUnits(FEE_MIN_AND_MAX_RESTRICTIONS.minPercentage, tenantCurrency),
      t`Percentage must be greater than or equal to ${fromMinorUnits(
        FEE_MIN_AND_MAX_RESTRICTIONS.minPercentage,
        tenantCurrency
      )}%`
    )
    .max(
      fromMinorUnits(FEE_MIN_AND_MAX_RESTRICTIONS.maxPercentage, tenantCurrency),
      t`Percentage must not be larger than ${fromMinorUnits(
        FEE_MIN_AND_MAX_RESTRICTIONS.maxPercentage,
        tenantCurrency
      )}%`
    );

export const getSplitFixedSchema = (
  locale: LocaleCode,
  min: number,
  max: number,
  tenantCurrency: CurrencyCode
) =>
  z.coerce
    .number({
      // biome-ignore lint/style/useNamingConvention: ZOD conventions
      invalid_type_error: t`Fee must be a number`,
      // biome-ignore lint/style/useNamingConvention: ZOD conventions
      required_error: t`Fee is required`,
    })
    .multipleOf(0.01, t`Fee must be a multiple of 0.01`)
    .min(
      fromMinorUnits(min, tenantCurrency),
      t`Fee must be greater than or equal to ${formatAmount(min, locale, tenantCurrency)}`
    )
    .max(
      fromMinorUnits(max, tenantCurrency),
      t`Fee must be less than or equal to ${formatAmount(max, locale, tenantCurrency)}`
    );

export const getSplitConfigurationGroupFormSchema = (
  locale: LocaleCode,
  tenantCurrency: CurrencyCode
) =>
  z
    .object({
      name: z
        .string()
        .min(1, t`Name is required`)
        .max(100, t`Name must be less than 100 characters`),
      isDefault: z.boolean(),
      isActive: z.boolean(),

      rules: z.array(
        z.object({
          pricingModel: z.enum(['FLAT', 'COST_PLUS']),
          paymentMethod: z.nativeEnum(ExtendedPaymentMethodEnum),
          shopperInteraction: z.enum(['Ecommerce', 'ContAuth', 'Moto', 'POS', 'ANY']),
          splitFixed: z.number().nonnegative().nullable(),
          splitPercentage: z.number().nonnegative().nullable(),
        })
      ),

      chargebackFeesModel: z.enum(['TENANT_PAY', 'MERCHANT_PAY']),
      additionalChargebackFee: getSplitFixedSchema(
        locale,
        0,
        FEE_MIN_AND_MAX_RESTRICTIONS.maxPerChargebackFee,
        tenantCurrency
      ).optional(),
      refundSplitModel: z.enum(['MERCHANT_PAY', 'REVERSE_PAYMENT_SPLIT']),
      refundFeesModel: z.enum(['TENANT_PAY', 'MERCHANT_PAY']),
      additionalRefundFee: getSplitFixedSchema(
        locale,
        0,
        FEE_MIN_AND_MAX_RESTRICTIONS.maxPerRefundFee,
        tenantCurrency
      ).optional(),
      showOverrideFeeInput: z.boolean(),
      overrideFeeSectionContent: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (!data.rules || data.rules.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['rules'],
          message: t`At least one rule is required.`,
        });
      }
    });

export type SplitConfigurationGroupFormData = z.infer<
  ReturnType<typeof getSplitConfigurationGroupFormSchema>
>;

export type SplitConfigurationRule = z.infer<
  ReturnType<typeof getSplitConfigurationGroupFormSchema>
>['rules'][number];

type Props = {
  form: UseFormReturn<SplitConfigurationGroupFormData>;
  readOnly?: boolean;
  storesCount?: number;
};

export const TransactionFeeGroupForm = ({form, readOnly, storesCount}: Props) => {
  const {currency, paymentMethods} = useStore();
  const {locale} = useLocale();
  const {
    register,
    control,
    formState: {errors},
    watch,
  } = form;
  const isActive = watch('isActive');
  const showOverrideFeeInput = watch('showOverrideFeeInput');
  const html = watch('overrideFeeSectionContent');

  const additionalChargebackFee = watch('additionalChargebackFee');
  const additionalRefundFee = watch('additionalRefundFee');

  const readOnlyClassName = readOnly ? 'bg-gray-100 text-gray-400' : '';

  const currencySymbol = (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

  const {
    isOpen: isAddRuleFormVisible,
    open: openAddRuleForm,
    close: closeAddRuleForm,
    onSuccess: onAddRuleSuccess,
  } = useDrawerState({
    onSuccessHandler: () => {},
  });

  const {append, remove} = useFieldArray({
    control,
    name: 'rules',
  });

  const rules = watch('rules');

  const possibleRules = useMemo(() => {
    return Object.values(ExtendedPaymentMethodEnum)
      .filter(method => {
        if (method === ExtendedPaymentMethodEnum.ANY) {
          return true;
        }
        return paymentMethods?.includes(method);
      })
      .flatMap(paymentMethod =>
        allShopperInteractions.map(shopperInteraction => ({
          paymentMethod,
          shopperInteraction,
        }))
      );
  }, [paymentMethods]);

  const canAddMoreRules = useMemo(() => {
    return possibleRules.some(
      ({paymentMethod, shopperInteraction}) =>
        !rules?.some(
          rule =>
            rule.paymentMethod === paymentMethod && rule.shopperInteraction === shopperInteraction
        )
    );
  }, [possibleRules, rules]);

  const handleAddRule = (rule: AddRuleFormData) => {
    append({
      ...rule,
      splitPercentage:
        rule.pricingModel === 'FLAT'
          ? rule.splitPercentageFlat
            ? toMinorUnits(rule.splitPercentageFlat, currency)
            : 0
          : rule.splitPercentageCostPlus || 0,
      splitFixed: rule.splitFixed ? toMinorUnits(rule.splitFixed, currency) : 0,
    });
  };

  return (
    <div>
      <Section title={<Trans>General</Trans>}>
        <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
          <Label text={t`Name`}>
            <InputText
              {...register('name', {required: true})}
              hasError={Boolean(errors.name)}
              readOnly={readOnly}
              disabled={readOnly}
              placeholder={t`Fee Group Name`}
              className={readOnlyClassName}
            />
            <ValidationError isVisible={Boolean(errors.name)}>
              {errors.name?.message}
            </ValidationError>
          </Label>

          <div>
            <InputToggle
              toggleSize="lg"
              {...register('isDefault')}
              readOnly={readOnly}
              disabled={readOnly}
              label={<Trans>Set as Default</Trans>}
            />
            <Typography variant="field-helper" className="mt-2">
              <Trans>
                When onboarding a new merchant, these fees will be selected, but you can pick from
                any active fee sets.
              </Trans>
            </Typography>
          </div>
          <div>
            <InputToggle
              toggleSize="lg"
              {...register('isActive')}
              readOnly={readOnly}
              disabled={readOnly}
              label={<Trans>Active</Trans>}
            />
            <Typography variant="field-helper" className="mt-2">
              {isActive && <Trans>All merchants can be added to this fee group</Trans>}

              {!isActive && !storesCount && (
                <Trans>New stores cannot be assigned to this fee group</Trans>
              )}

              {!isActive && storesCount && (
                <Trans>
                  This fee group still applies to {storesCount} stores, but new stores cannot use
                  this fee group
                </Trans>
              )}
            </Typography>
          </div>
        </div>
      </Section>
      <Section
        title={
          <div className="flex items-center justify-between">
            <Typography variant="header-form-section">
              <Trans>Payment Rules</Trans>
            </Typography>
            {!readOnly && canAddMoreRules && (
              <Button
                type="button"
                variant="primary"
                size="lg"
                onClick={openAddRuleForm}
                className="w-fit max-sm:w-full"
              >
                <Trans>Add New</Trans>
              </Button>
            )}
          </div>
        }
        subTitle={
          <Trans>
            <p>Your payment rules work from general (ANY) to specific. For example, if you have:</p>
            <ul className="my-2 ml-5 list-disc">
              <li>A rule that applies to ANY payment methods (rate: 3% + {currencySymbol}0.30)</li>
              <li>A rule specifically for AMEX credit card (rate: 3.5% + {currencySymbol}0.25)</li>
              <li>
                A rule specifically for AMEX credit card and POS entry mode (rate: 3.2% +{' '}
                {currencySymbol}0.15)
              </li>
            </ul>
            <p>
              When a customer pays with an AMEX credit card, the AMEX credit card rule applies
              because it's more specific. The general (ANY) payment rule only applies to payment
              methods that don't have their own specific rules. When a customer pays using a POS
              device the second AMEX rule applies because it is more specific than the first AMEX
              rule.
            </p>
          </Trans>
        }
      >
        <PaymentRulesList rulesList={rules} remove={remove} readOnly={readOnly} />
        <ValidationError isVisible={Boolean(errors.rules)}>{errors.rules?.message}</ValidationError>
      </Section>
      <Section
        title={<Trans>Chargeback Fees</Trans>}
        subTitle={
          <Trans>
            <p>
              Adyen charges you for chargebacks based on your agreement. You can either cover these
              fees yourself or pass them on to the merchant. Additionally, you can impose an extra
              fee per transaction on the merchant. For instance, if a chargeback costs{' '}
              {currencySymbol}10 and you want to charge the merchant {currencySymbol}25, you have
              two options:
            </p>

            <ul className="my-2 ml-5 list-disc">
              <li>
                Pay the {currencySymbol}10 yourself and bill the merchant an additional{' '}
                {currencySymbol}25. Using the "We Pay" option and adding the {currencySymbol}25 to
                the "Additional Fee" field.
              </li>
              <li>
                Have the merchant pay the {currencySymbol}10 and an extra {currencySymbol}15,
                resulting in two separate transactions. Use the "Merchant Pays" option and add the{' '}
                {currencySymbol}15 to the "Additional Fee" field. Note: the merchant will see them
                as two separate transactions.
              </li>
            </ul>

            <p>
              Extra fees are charged either at the time of the chargeback or as soon as funds are
              available.
            </p>
          </Trans>
        }
      >
        <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
          <Typography variant="header-form-section">
            <Trans>Select who pays for Adyen Fees</Trans>
          </Typography>
          <div className="flex items-center gap-x-16">
            <InputRadio
              // TODO: Clean up after 1.9.0 convert to chargebackFeeModel
              {...register('chargebackFeesModel')}
              value="TENANT_PAY"
              label={<Trans>We pay</Trans>}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <InputRadio
              // TODO: Clean up after 1.9.0 convert to chargebackFeeModel
              {...register('chargebackFeesModel')}
              value="MERCHANT_PAY"
              label={<Trans>Merchant Pays</Trans>}
              readOnly={readOnly}
              disabled={readOnly}
            />
          </div>

          <Label
            text={t`Additional fee Charged to Merchant Per Occurrence ${currencySymbol} (independent from Adyen Fees)`}
          >
            <InputText
              {...register('additionalChargebackFee')}
              addonBefore={currencySymbol}
              value={
                readOnly && additionalChargebackFee
                  ? additionalChargebackFee.toFixed(2)
                  : additionalChargebackFee
              }
              readOnly={readOnly}
              disabled={readOnly}
              className={readOnlyClassName}
              hasError={Boolean(errors.additionalChargebackFee)}
            />
            <ValidationError isVisible={Boolean(errors.additionalChargebackFee)}>
              {errors.additionalChargebackFee?.message}
            </ValidationError>
          </Label>
        </div>
      </Section>
      <Section
        title={<Trans>Refund Split</Trans>}
        subTitle={
          <Trans>
            The refund split determines the source account(s) from which the refund amount is
            issued.
          </Trans>
        }
      >
        <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
          <InputRadio
            {...register('refundSplitModel')}
            value="MERCHANT_PAY"
            label={
              <span className="flex items-center gap-2">
                <div className="min-w-28">
                  <Trans>Merchant Pays</Trans>
                </div>
                <Typography variant="field-helper" className="pt-0.5">
                  <Trans>Full refund amount is deducted from the merchant.</Trans>
                </Typography>
              </span>
            }
            readOnly={readOnly}
            disabled={readOnly}
          />
          <InputRadio
            {...register('refundSplitModel')}
            value="REVERSE_PAYMENT_SPLIT"
            label={
              <span className="flex items-center gap-2">
                <div className="min-w-28">
                  <Trans>Reverse Payment Split</Trans>
                </div>

                <Typography variant="field-helper" className="pt-0.5">
                  <Trans>
                    Deducts evenly in the same way the original transaction occurred. For example,
                    if the original transaction of {currencySymbol}100 was split with{' '}
                    {currencySymbol}97 going to the merchant and {currencySymbol}3 commission to
                    you, the reversal will also follow this split. In a refund, {currencySymbol}97
                    would be returned from the merchant's account and {currencySymbol}3 from the
                    liable's account.
                  </Trans>
                </Typography>
              </span>
            }
            readOnly={readOnly}
            disabled={readOnly}
          />
        </div>
      </Section>
      <Section
        title={<Trans>Refund Fees</Trans>}
        subTitle={
          <Trans>
            <p>
              Similar to chargebacks, Adyen charges a small fee for refunds. You can either cover
              these fees yourself or pass them on to the merchant. Additionally, you can impose an
              extra fee per transaction on the merchant. For instance, if a fee costs{' '}
              {currencySymbol}1 and you want to charge the merchant {currencySymbol}3, you have two
              options:
            </p>

            <ul className="my-2 ml-5 list-disc">
              <li>
                Pay the {currencySymbol}1 yourself and bill the merchant an additional{' '}
                {currencySymbol}3. Using the "We Pay" option and adding the {currencySymbol}3 to the
                "Additional Fee" field.
              </li>
              <li>
                Have the merchant pay the {currencySymbol}1 and an extra {currencySymbol}2,
                resulting in two separate transactions. Use the "Merchant Pays" option and add the{' '}
                {currencySymbol}2 to the "Additional Fee" field. Note: the merchant will see them as
                two separate transactions.
              </li>
            </ul>

            <p>
              Extra fees are charged either at the time of a refund or as soon as funds are
              available.
            </p>
          </Trans>
        }
      >
        <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
          <Typography variant="header-form-section">
            <Trans>Select who pays for Adyen Fees</Trans>
          </Typography>
          <InputRadio
            {...register('refundFeesModel')}
            value="TENANT_PAY"
            label={<Trans>We pay</Trans>}
            readOnly={readOnly}
            disabled={readOnly}
          />
          <InputRadio
            {...register('refundFeesModel')}
            value="MERCHANT_PAY"
            label={<Trans>Merchant Pays</Trans>}
            readOnly={readOnly}
            disabled={readOnly}
          />

          <Label
            text={t`Additional fee Charged to Merchant Per Occurrence ${currencySymbol} (independent from Adyen Fees)`}
          >
            <InputText
              {...register('additionalRefundFee')}
              value={
                readOnly && additionalRefundFee
                  ? additionalRefundFee.toFixed(2)
                  : additionalRefundFee
              }
              readOnly={readOnly}
              disabled={readOnly}
              className={readOnlyClassName}
              addonBefore={currencySymbol}
              hasError={Boolean(errors.additionalRefundFee)}
            />
            <ValidationError isVisible={Boolean(errors.additionalRefundFee)}>
              {errors.additionalRefundFee?.message}
            </ValidationError>
          </Label>
        </div>
      </Section>
      <OverridePricingSection
        html={html}
        readOnly={readOnly}
        register={register}
        showOverrideFeeInput={showOverrideFeeInput}
      />
      <AddRulePanel
        isOpen={isAddRuleFormVisible}
        onClose={closeAddRuleForm}
        onSuccess={onAddRuleSuccess}
        handleAddRule={handleAddRule}
        existingRules={rules?.map(rule => ({
          paymentMethod: rule.paymentMethod,
          shopperInteraction: rule.shopperInteraction,
        }))}
      />
    </div>
  );
};
