import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {SplitConfigurationGroupListItemOutput} from '@zentact/api/src/trpc/routers/splitConfigurationGroupRouter';
import {TenantIntervalFeesGroupListItemOutput} from '@zentact/api/src/trpc/routers/tenantIntervalFeesGroupRouter';
import {EntityPicker, InputText, Label, ValidationError} from '@zentact/ui-tailwind';
import {InputRadio, InputSearchSelect} from '@zentact/ui-tailwind/forms';
import {useCallback, useEffect, useMemo} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import z from 'zod';

type ValidOrganizationData =
  | {
      organizationType: 'new';
      organizationName: string;
      organizationSupportEmail?: string;
      organizationReferenceId?: string;
      organizationId?: string;
    }
  | {
      organizationType: 'existing';
      organizationId: string;
      organizationName?: string;
      organizationSupportEmail?: string;
      organizationReferenceId?: string;
    };

export const NOT_SELECTED_TENANT_INTERVAL_FEE_GROUP_ID = 'not selected';

export const getOrganizationFormSchema = (merchantReferenceIdRequired?: boolean) =>
  z.object({
    merchantName: z.string().min(1, t`Merchant Account name is required`),
    merchantEmail: z
      .string()
      .min(1, t`Email is required`)
      .max(70, t`The length of the email address can't exceed 70 characters`)
      .email(t`Email is not valid`),
    merchantReferenceId: merchantReferenceIdRequired
      ? z
          .string()
          .trim()
          .min(1, t`Merchant Reference ID is required`)
          .max(80, t`The length of the reference id can't exceed 80 characters`)
      : z.string().max(80, t`The length of the reference id can't exceed 80 characters`),
    organization: z
      .object({
        organizationType: z.literal('new').or(z.literal('existing')),
        organizationName: z.string().optional(),
        organizationReferenceId: z
          .string()
          .trim()
          .max(50, t`Reference ID must not exceed 50 characters`)
          .optional(),
        organizationSupportEmail: z
          .string()
          .max(50, t`Email address must not exceed 50 characters`)
          .email(t`Email is not valid`)
          .optional()
          .or(z.literal('')),
        organizationId: z.string().optional(),
      })
      .superRefine((data, ctx): data is ValidOrganizationData => {
        if (data.organizationType === 'new') {
          if (!data.organizationName) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t`Organization name is required`,
              path: ['organizationName'],
            });
          }
        } else {
          if (!data.organizationId) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t`Organization is not selected`,
              path: ['organizationId'],
            });
          }
        }
        return z.NEVER;
      }),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    splitConfigurationGroupId: z.string({required_error: t`Transaction Fee Group is not selected`}),
    tenantIntervalFeesGroupId: z
      .string()
      .transform(v => (v === NOT_SELECTED_TENANT_INTERVAL_FEE_GROUP_ID ? null : v))
      .nullable()
      .optional(),
  });

export type OrganizationFormData = z.infer<ReturnType<typeof getOrganizationFormSchema>>;

type OrganizationFormProps = {
  form: UseFormReturn<OrganizationFormData>;
  organizationReadOnly?: boolean;
  splitConfigurationGroups: SplitConfigurationGroupListItemOutput[];
  tenantIntervalFeesGroups: TenantIntervalFeesGroupListItemOutput[];
  merchantReferenceIdRequired?: boolean;
  organizationId?: string | null;
};

export const OrganizationForm = ({
  form,
  organizationReadOnly,
  splitConfigurationGroups,
  tenantIntervalFeesGroups,
  merchantReferenceIdRequired,
  organizationId,
}: OrganizationFormProps) => {
  const {
    register,
    setValue,
    watch,
    formState: {errors},
  } = form;
  const {organizationList} = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const organizationType = watch('organization.organizationType');
  const splitConfigurationGroupId = watch('splitConfigurationGroupId');
  const tenantIntervalFeesGroupId = watch('tenantIntervalFeesGroupId');

  const tenantIntervalFeesGroupOptions = useMemo(() => {
    return [
      {
        id: NOT_SELECTED_TENANT_INTERVAL_FEE_GROUP_ID,
        label: t`Not selected`,
      },
      ...tenantIntervalFeesGroups.map(group => ({
        id: group.id,
        label: group.name,
      })),
    ];
  }, [tenantIntervalFeesGroups]);

  useEffect(() => {
    if (organizationId) {
      form.setValue('organization.organizationId', organizationId);
      form.setValue('organization.organizationType', 'existing');
    } else {
      form.setValue('organization.organizationType', 'new');
    }
  }, [organizationId, form]);

  const onOverrideFeeGroupClick = useCallback(() => {
    const returnTo = `${location.pathname}${location.search}`;
    const formData = watch();

    // save a state for back button
    navigate(returnTo, {replace: true, state: {inviteOrganizationFormData: formData}});

    navigate(
      `${RoutePath.TRANSACTION_FEE_GROUP_CREATE}?sourceFeeGroupId=${splitConfigurationGroupId}`,
      {
        state: {returnTo, returnToState: {inviteOrganizationFormData: formData}},
      }
    );
  }, [location.pathname, location.search, splitConfigurationGroupId, watch]);

  return (
    <div className="flex flex-col gap-3">
      <div className="mb-3">
        <label className="flex flex-col">
          <h2 className="text-base font-semibold leading-6 text-gray-800">{t`Organization`}</h2>
          <p className="mt-1 text-sm leading-5 text-gray-500">
            {t`In Zentact, each merchant is associated to an organization. Some organizations will have multiple merchants (like a franchise or different branch locations), others will have a one to one relationship between organization and merchant.`}
          </p>
        </label>
        {!organizationReadOnly && (
          <div className="flex items-center mt-3 gap-x-16">
            <InputRadio
              {...register('organization.organizationType')}
              value="new"
              label={<Trans>New Organization</Trans>}
            />
            <InputRadio
              {...register('organization.organizationType')}
              value="existing"
              label={<Trans>Existing Organization</Trans>}
            />
          </div>
        )}
        <div className="mt-3">
          {organizationType === 'new' ? (
            <div>
              <Label text={t`Organization Name`}>
                <InputText
                  {...register('organization.organizationName', {required: true})}
                  hasError={Boolean(errors.organization?.organizationName)}
                  readOnly={organizationReadOnly}
                  disabled={organizationReadOnly}
                  placeholder={t`e.g Acme Corporation`}
                  className={organizationReadOnly ? 'bg-gray-100 text-gray-400' : ''}
                />
                <ValidationError isVisible={Boolean(errors.organization?.organizationName)}>
                  {errors.organization?.organizationName?.message}
                </ValidationError>
              </Label>
              {!organizationReadOnly && (
                <>
                  <Label className="mt-3" text={t`Organization Reference ID (optional)`}>
                    <InputText
                      {...register('organization.organizationReferenceId', {required: true})}
                      hasError={Boolean(errors.organization?.organizationReferenceId)}
                      placeholder="e.g. 1231123213123"
                    />
                    <ValidationError
                      isVisible={Boolean(errors.organization?.organizationReferenceId)}
                    >
                      {errors.organization?.organizationReferenceId?.message}
                    </ValidationError>
                  </Label>
                  <Label className="mt-3" text={t`Organization Support Email (optional)`}>
                    <InputText
                      {...register('organization.organizationSupportEmail', {required: true})}
                      hasError={Boolean(errors.organization?.organizationSupportEmail)}
                      placeholder={t`e.g help@acme.com`}
                    />
                    <ValidationError
                      isVisible={Boolean(errors.organization?.organizationSupportEmail)}
                    >
                      {errors.organization?.organizationSupportEmail?.message}
                    </ValidationError>
                    <p className="text-xs leading-5 text-gray-500 ">
                      <Trans>This is shown to customers to help them get support</Trans>
                    </p>
                  </Label>
                </>
              )}
            </div>
          ) : (
            <Label text={t`Organization Name`}>
              <EntityPicker
                className="w-full"
                selected={watch('organization.organizationId')}
                onChange={val => setValue('organization.organizationId', val)}
                options={organizationList}
                label={t`Select Organization`}
                excludeDefaultOption
              />
              <ValidationError isVisible={Boolean(errors.organization?.organizationId)}>
                {errors.organization?.organizationId?.message}
              </ValidationError>
            </Label>
          )}
        </div>
      </div>
      <div>
        <label className="flex flex-col">
          <h2 className="text-base font-semibold leading-6 text-gray-800">{t`Merchant`}</h2>
          <p className="mt-1 text-sm leading-5 text-gray-500">
            {t`Please enter a unique name for your merchant to identify their account in Zentact. This is separate from the legal business name and/or DBA they'll provide on their merchant application during onboarding.`}
          </p>
        </label>
        <Label text={t`Merchant Account Name`} className="mt-3">
          <InputText
            {...register('merchantName', {required: true})}
            hasError={Boolean(errors.merchantName)}
            placeholder={t`e.g. Acme Corporation Boston`}
          />
          <ValidationError isVisible={Boolean(errors.merchantName)}>
            {errors.merchantName?.message}
          </ValidationError>
        </Label>
        <Label text={t`Merchant Contact Email`} className="mt-3">
          <InputText
            {...register('merchantEmail', {required: true})}
            placeholder="e.g. bob@acme.com"
            hasError={Boolean(errors.merchantEmail)}
          />
          <ValidationError isVisible={Boolean(errors.merchantEmail)}>
            {errors.merchantEmail?.message}
          </ValidationError>
          <p className="text-xs leading-5 text-gray-500 ">
            {t`This is where the invitation will be sent. Make sure this belongs to a legally authorized signer.`}
          </p>
        </Label>
        <Label
          text={t`Merchant Reference ID${!merchantReferenceIdRequired ? ' (optional)' : ''}`}
          className="mt-3"
        >
          <InputText
            {...register('merchantReferenceId', {required: true})}
            hasError={Boolean(errors.merchantReferenceId)}
            placeholder="e.g. 1231123213123"
          />
          <ValidationError isVisible={Boolean(errors.merchantReferenceId)}>
            {errors.merchantReferenceId?.message}
          </ValidationError>
          <p className="text-xs leading-5 text-gray-500">
            {t`This is for record keeping only. If you have an internal reference ID to refer to this merchant, you can add it here to make reporting easier.`}
          </p>
        </Label>
        <Label
          text={
            <div className="flex justify-between">
              <span>
                <Trans>Transaction Fee Group</Trans>
              </span>
              <Link
                to={RoutePath.TRANSACTION_FEE_GROUPS}
                target="_blank"
                className="text-primary-600"
              >
                <Trans>View Fee Groups</Trans>
              </Link>
            </div>
          }
          className="mt-8 mb-10"
        >
          <InputSearchSelect
            className="mb-2"
            value={splitConfigurationGroupId}
            onChange={val =>
              setValue(
                'splitConfigurationGroupId',
                Array.isArray(val) ? (val[val.length - 1] as string) : val
              )
            }
            options={splitConfigurationGroups.map(group => ({id: group.id, label: group.name}))}
            placeholder={t`Please select a transaction fee group`}
          />
          <ValidationError isVisible={Boolean(errors.splitConfigurationGroupId)}>
            {errors.splitConfigurationGroupId?.message}
          </ValidationError>
          {!organizationReadOnly && splitConfigurationGroupId && (
            // biome-ignore lint/a11y/useKeyWithClickEvents:
            <div
              className="text-sm cursor-pointer text-primary-600"
              onClick={onOverrideFeeGroupClick}
            >
              <Trans>
                Copy and Customize "
                {
                  splitConfigurationGroups.find(group => group.id === splitConfigurationGroupId)
                    ?.name
                }
                "
              </Trans>
            </div>
          )}
        </Label>
        <Label
          text={
            <div className="flex justify-between">
              <span>
                <Trans>Recurring Fee Group (optional)</Trans>
              </span>
              <Link
                to={RoutePath.RECURRING_FEE_GROUPS}
                target="_blank"
                className="text-primary-600"
              >
                <Trans>View Fee Groups</Trans>
              </Link>
            </div>
          }
          className="mt-8 mb-10"
        >
          <InputSearchSelect
            className="mb-2"
            value={tenantIntervalFeesGroupId ?? undefined}
            onChange={val =>
              setValue(
                'tenantIntervalFeesGroupId',
                Array.isArray(val) ? (val[val.length - 1] as string) : val
              )
            }
            options={tenantIntervalFeesGroupOptions}
            placeholder={t`Please select a recurring fee group`}
          />
          <ValidationError isVisible={Boolean(errors.tenantIntervalFeesGroupId)}>
            {errors.tenantIntervalFeesGroupId?.message}
          </ValidationError>
        </Label>
      </div>
    </div>
  );
};
