import {XMarkIcon} from '@heroicons/react/20/solid';
import {useLingui} from '@lingui/react';
import {AlertOverlayWithConfirmation} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';
import {UseFieldArrayRemove} from 'react-hook-form';

type Props = {
  index: number;
  remove: UseFieldArrayRemove;
};

export const RuleActions = ({index, remove}: Props) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const {i18n} = useLingui();

  const handleDeleteRule = useCallback(() => {
    remove(index);
  }, [index]);

  return (
    <>
      <XMarkIcon
        className="w-6 h-6"
        aria-hidden="true"
        onClick={() => setDeleteConfirmationOpen(prev => !prev)}
      />
      {deleteConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={deleteConfirmationOpen}
          setOpen={setDeleteConfirmationOpen}
          handleAction={handleDeleteRule}
          localeText={{
            title: i18n._('Delete rule'),
            description: i18n._('Are you sure you want to delete this rule?'),
            confirm: i18n._('Delete'),
            cancel: i18n._('Cancel'),
          }}
        />
      )}
    </>
  );
};
