import {Combobox, Transition} from '@headlessui/react';
import {CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon} from '@heroicons/react/20/solid';
import {useLingui} from '@lingui/react';
import {ComponentProps, Fragment, Ref, forwardRef, useMemo, useState} from 'react';
import {InputSelectOption, Label} from '../..';
import {cn} from '../../utils';

export type InputSearchSelectProps<T extends string> = Omit<
  ComponentProps<typeof Combobox>,
  'value' | 'onChange' | 'multiple'
> & {
  options: InputSelectOption<T>[];
  value?: T | T[];
  onChange?: (value: T | T[]) => void;
  placeholder?: string;
  label?: string;
  className?: string;
  labelClassName?: string;
  selectedItemLabel?: string;
  inSidePanel?: boolean;
  multiple?: boolean;
};

function InputSearchSelectComponent<Id extends string>(
  props: InputSearchSelectProps<Id>,
  ref: Ref<HTMLDivElement>
) {
  const {
    options,
    value,
    onChange,
    placeholder,
    className,
    label,
    labelClassName,
    selectedItemLabel,
    inSidePanel,
    multiple,
    ...restProps
  } = props;
  const [query, setQuery] = useState('');
  const {i18n} = useLingui();

  const filteredOptions =
    query === ''
      ? options
      : options.filter(option =>
          option.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const placeholderLabel = <span className="flex items-center text-gray-400">{placeholder}</span>;

  const inputLabel = useMemo(() => {
    if (Array.isArray(value) && value.length > 1) {
      return i18n._('Multiple items selected');
    }
    const item = options.find(opt =>
      Array.isArray(value) ? value.includes(opt.id) : value === opt.id
    );
    if (item) {
      return (
        <span className="flex items-center">
          {item.iconUrl ? (
            <img src={item.iconUrl} alt="" className="w-5 h-5 rounded-full shrink-0" />
          ) : null}
          <span className={cn('block truncate', item.iconUrl && 'ml-3')}>{item.label}</span>
        </span>
      );
    }
    return placeholderLabel;
  }, [value, options, placeholderLabel]);

  return (
    <Combobox
      as="div"
      ref={ref}
      value={value}
      {...restProps}
      multiple={multiple as false} // types fix
      onChange={onChange}
      className={cn('relative', className)}
      onClick={(e: Event) => e.preventDefault()} // fixes an issue where component fails to close on click inside slideover
    >
      {({open}) => (
        <>
          <Label text={label} className={labelClassName}>
            <Combobox.Button className="focus:ring-primary-500 relative min-h-[2.25rem] w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 disabled:bg-slate-100 sm:text-sm sm:leading-6 disabled:cursor-not-allowed">
              {selectedItemLabel || inputLabel}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                <ChevronUpDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </span>
            </Combobox.Button>
          </Label>
          <Transition
            show={open}
            as={Fragment}
            leaveTo="opacity-0"
            leaveFrom="opacity-100"
            leave="transition ease-in duration-100"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options
              static
              className={`${
                inSidePanel ? '' : 'absolute'
              } z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black/5 focus:outline-none sm:text-sm`}
            >
              <div className="flex items-center pl-3 pr-10">
                <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                <Combobox.Input
                  displayValue={_val => query}
                  className="w-full text-sm leading-5 text-left text-gray-900 border-none focus:ring-0 sm:text-sm sm:leading-6"
                  onChange={event => setQuery(event.target.value)}
                />
              </div>
              {filteredOptions.length === 0 && query !== '' ? (
                <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                  Nothing found.
                </div>
              ) : (
                filteredOptions.map(option => (
                  <Combobox.Option
                    key={option.id}
                    className={({active}) =>
                      cn(
                        active
                          ? cn('bg-primary-600 text-white', option.activeClassName)
                          : cn('text-gray-900', option.className),
                        'relative cursor-default select-none py-2 pl-3 pr-9 '
                      )
                    }
                    data-te-toggle={!!option.tooltipText && 'tooltip'}
                    title={option.tooltipText}
                    value={option.id}
                    disabled={option.disabled}
                  >
                    {({selected, active}) => (
                      <>
                        <div className="flex items-center ">
                          {option.iconUrl ? (
                            <img
                              src={option.iconUrl}
                              className="w-5 h-5 rounded-full shrink-0"
                              alt="icon"
                            />
                          ) : null}
                          <span
                            className={cn(
                              selected ? 'font-semibold' : 'font-normal',
                              option.iconUrl && 'ml-3',
                              'block truncate'
                            )}
                          >
                            {option.label}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={cn(
                              active ? 'text-white' : 'text-primary-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </>
      )}
    </Combobox>
  );
}

export const InputSearchSelect = forwardRef(InputSearchSelectComponent);

InputSearchSelect.displayName = 'InputSearchSelect';
