import {MerchantAccountPublicStatus} from '@zentact/common';
import {FlatPillWithDot} from '../../elements';

type PillProps = React.ComponentProps<typeof FlatPillWithDot>;

export const merchantAccountStatusToColor: Record<MerchantAccountPublicStatus, PillProps['color']> =
  {
    [MerchantAccountPublicStatus.ACTIVE]: 'green',
    [MerchantAccountPublicStatus.ACTIVE_ALLOWED_APP_CHANGES]: 'green',
    [MerchantAccountPublicStatus.INITIATED]: 'yellow',
    [MerchantAccountPublicStatus.INVITED]: 'yellow',
    [MerchantAccountPublicStatus.INVITE_ACCEPTED]: 'yellow',
    [MerchantAccountPublicStatus.APPLICATION_IN_PROGRESS]: 'yellow',
    [MerchantAccountPublicStatus.APPLICATION_IN_REVIEW]: 'yellow',
    [MerchantAccountPublicStatus.INVITE_EXPIRED]: 'red',
    [MerchantAccountPublicStatus.INVITE_REVOKED]: 'gray',
    [MerchantAccountPublicStatus.DEACTIVATED]: 'gray',
    [MerchantAccountPublicStatus.APPLICATION_REVOKED]: 'gray',
    [MerchantAccountPublicStatus.REJECTED]: 'red',
    [MerchantAccountPublicStatus.NEEDS_ORGANIZATION]: 'red',
    [MerchantAccountPublicStatus.ASSIGNING_ORGANIZATION]: 'gray',
    [MerchantAccountPublicStatus.ADDITIONAL_DATA_REQUIRED]: 'yellow',
    [MerchantAccountPublicStatus.CLOSING]: 'gray',
  };
