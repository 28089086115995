import {useLocale} from '@/hooks';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {
  CurrencyCode,
  FEE_MIN_AND_MAX_RESTRICTIONS,
  LocaleCode,
  ShopperInteractionPublicEnum,
  allShopperInteractions,
  formatAmount,
  fromMinorUnits,
  shopperInteractionLabels,
  toMinorUnits,
} from '@zentact/common';
import {
  ExtendedPaymentMethodEnum,
  ExtendedPaymentMethodEnumDisplayMap,
  ExtendedPaymentMethodType,
} from '@zentact/common';
import {
  InputRadio,
  InputSearchSelect,
  InputText,
  Label,
  Typography,
  ValidationError,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useMemo} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';
import z from 'zod';
import {getSplitFixedSchema, getSplitPercentageSchema} from './transaction-fee-group-form';

export const addRuleFormSchema = (locale: LocaleCode, tenantCurrency: CurrencyCode) =>
  z
    .object({
      shopperInteraction: z.enum(['Ecommerce', 'ContAuth', 'Moto', 'POS', 'ANY'], {
        // biome-ignore lint/style/useNamingConvention: <explanation>
        required_error: t`Please select an entry mode`,
      }),
      paymentMethod: z.nativeEnum(ExtendedPaymentMethodEnum, {
        // biome-ignore lint/style/useNamingConvention: <explanation>
        required_error: t`Please select a payment method`,
      }),

      pricingModel: z.enum(['FLAT', 'COST_PLUS']),
      splitPercentageFlat: getSplitPercentageSchema(tenantCurrency).optional(),
      splitPercentageCostPlus: z.coerce
        .number({
          // biome-ignore lint/style/useNamingConvention: ZOD conventions
          invalid_type_error: t`Percentage must be a number`,
          // biome-ignore lint/style/useNamingConvention: ZOD conventions
          required_error: t`Fee is required`,
        })
        .int(t`Basis points must be a whole number (e.g., 100 = 1.00%)`)
        .min(
          FEE_MIN_AND_MAX_RESTRICTIONS.minPercentage,
          t`Percentage must be greater than or equal to ${fromMinorUnits(
            FEE_MIN_AND_MAX_RESTRICTIONS.minPercentage,
            tenantCurrency
          )}%`
        )
        .max(
          FEE_MIN_AND_MAX_RESTRICTIONS.maxPercentage,
          t`Percentage must not be larger than ${fromMinorUnits(
            FEE_MIN_AND_MAX_RESTRICTIONS.maxPercentage,
            tenantCurrency
          )}%`
        )
        .optional(),
      splitFixed: getSplitFixedSchema(
        locale,
        FEE_MIN_AND_MAX_RESTRICTIONS.minFixedFee,
        FEE_MIN_AND_MAX_RESTRICTIONS.maxFixedFee,
        tenantCurrency
      ).optional(),
    })
    .superRefine((data, ctx) => {
      if (!data.splitPercentageFlat && !data.splitPercentageCostPlus && !data.splitFixed) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['splitPercentageFlat'],
          message: t`Card split percentage or fixed fee is required`,
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['splitPercentageCostPlus'],
          message: t`Card split percentage or fixed fee is required`,
        });
      }
    });

export type AddRuleFormData = z.infer<ReturnType<typeof addRuleFormSchema>>;

type AddRuleFormProps = {
  form: UseFormReturn<AddRuleFormData>;
  initialValues?: {email?: string};
  readOnly?: boolean;
  children?: JSX.Element;
  existingRules: {
    paymentMethod: ExtendedPaymentMethodType;
    shopperInteraction: 'Ecommerce' | 'ContAuth' | 'Moto' | 'POS' | 'ANY';
  }[];
};

export const AddRuleForm = ({form, readOnly, children, existingRules}: AddRuleFormProps) => {
  const {locale} = useLocale();
  const {tenant, currency, paymentMethods} = useStore();

  const {
    register,
    control,
    setValue,
    watch,
    resetField,
    formState: {errors},
  } = form;
  const selectedPaymentMethod = watch('paymentMethod');

  const selectedEntryMode = watch('shopperInteraction');

  const pricingModel = watch('pricingModel');
  const pricingModelField = register('pricingModel');
  const isCostPlusCardPricingModel = pricingModel === 'COST_PLUS';

  const splitPercentage =
    (pricingModel === 'FLAT'
      ? watch('splitPercentageFlat')
      : (watch('splitPercentageCostPlus') || 0) / 100) || 0;
  const splitFixed = watch('splitFixed') || 0;

  const currencySymbol = (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

  useEffect(() => {
    resetField('splitPercentageFlat');
    resetField('splitPercentageCostPlus');
  }, [pricingModel]);

  const isShopperInteractionAvailable = useCallback(
    (paymentMethod: ExtendedPaymentMethodType, shopperInteraction: string) => {
      return !existingRules.some(
        rule =>
          rule.paymentMethod === paymentMethod && rule.shopperInteraction === shopperInteraction
      );
    },
    [existingRules]
  );

  const availablePaymentMethods = useMemo(() => {
    return Object.values(ExtendedPaymentMethodEnum).filter(paymentMethod => {
      if (paymentMethod === ExtendedPaymentMethodEnum.ANY) {
        return allShopperInteractions.some(shopperInteraction =>
          isShopperInteractionAvailable(paymentMethod, shopperInteraction)
        );
      }

      return (
        paymentMethods?.includes(paymentMethod) &&
        allShopperInteractions.some(shopperInteraction =>
          isShopperInteractionAvailable(paymentMethod, shopperInteraction)
        )
      );
    });
  }, [isShopperInteractionAvailable, paymentMethods]);

  const shopperInteractionOptions = useMemo(() => {
    return allShopperInteractions.filter(shopperInteraction =>
      isShopperInteractionAvailable(selectedPaymentMethod, shopperInteraction)
    );
  }, [isShopperInteractionAvailable, selectedPaymentMethod]);

  useEffect(() => {
    const usedShopperInteractions = existingRules
      .filter(rule => rule.paymentMethod === selectedPaymentMethod)
      .map(rule => rule.shopperInteraction);

    const defaultShopperInteraction = shopperInteractionOptions[0];
    const nextAvailablePaymentMethod = availablePaymentMethods[0];

    if (defaultShopperInteraction && !usedShopperInteractions.includes(defaultShopperInteraction)) {
      setValue('shopperInteraction', defaultShopperInteraction, {shouldValidate: true});
    }

    if (!defaultShopperInteraction && nextAvailablePaymentMethod) {
      setValue('paymentMethod', nextAvailablePaymentMethod);
    }
  }, [
    selectedPaymentMethod,
    existingRules,
    setValue,
    availablePaymentMethods,
    shopperInteractionOptions,
  ]);

  return (
    <div className="flex flex-col gap-3">
      <Label text={t`Payment Method`}>
        <InputSearchSelect
          className="mb-2"
          value={selectedPaymentMethod}
          onChange={val =>
            setValue(
              'paymentMethod',
              Array.isArray(val)
                ? (val[val.length - 1] as ExtendedPaymentMethodType)
                : (val as ExtendedPaymentMethodType),
              {shouldValidate: true}
            )
          }
          options={availablePaymentMethods.map(mode => ({
            id: mode,
            label: ExtendedPaymentMethodEnumDisplayMap[mode],
          }))}
          placeholder={t`Please select a payment method`}
        />
        <ValidationError isVisible={Boolean(errors.paymentMethod)}>
          {errors.paymentMethod?.message}
        </ValidationError>
      </Label>
      <Label text={t`Commission`}>
        <div className="flex flex-col w-full gap-6">
          <div className="flex items-center gap-x-16">
            <InputRadio {...pricingModelField} value="COST_PLUS" label={<Trans>Cost Plus</Trans>} />
            <InputRadio
              {...pricingModelField}
              value="FLAT"
              label={<Trans>Flat Percentage</Trans>}
            />
          </div>

          <div className="sm:col-span-3">
            {isCostPlusCardPricingModel && (
              <Label text={t`Basis Points on Top of Cost (enter 25 for 0.25%)`}>
                <Controller
                  control={control}
                  name="splitPercentageCostPlus"
                  render={({field: {ref, ...rest}}) => (
                    <InputText
                      ref={ref}
                      {...rest}
                      hasError={Boolean(errors.splitPercentageCostPlus)}
                    />
                  )}
                />
                <ValidationError isVisible={Boolean(errors.splitPercentageCostPlus)}>
                  {errors.splitPercentageCostPlus?.message}
                </ValidationError>
                <Typography variant="field-helper" as="div" className="mt-2">
                  <p className="text-xs leading-5 text-gray-400">
                    {isCostPlusCardPricingModel && (
                      <Trans>
                        The Cost Plus model adds a fixed percentage (profit) on top of the total
                        processing cost, which includes merchant bank fees, scheme fees, Adyen
                        transaction fees, and interchange fees. The merchant is responsible for
                        variable processing costs, while {tenant?.name} collects a fixed percentage
                        (profit) of the transaction.
                      </Trans>
                    )}
                  </p>
                  <ul className="ml-5 text-xs text-gray-400 list-disc">
                    {[100].map((exampleAmount, i) => {
                      const fees = (exampleAmount / 100) * Number(splitPercentage);
                      const feeAmount = toMinorUnits(fees, currency);
                      const transactionAmount = toMinorUnits(exampleAmount - fees, currency);

                      return (
                        <li key={`example-${i}`}>
                          <Trans>
                            For example, a {formatAmount(exampleAmount * 100, locale, currency)}{' '}
                            transaction will have a {formatAmount(feeAmount, locale, currency)} fee.
                            The merchant will receive{' '}
                            {formatAmount(transactionAmount, locale, currency)}{' '}
                            {isCostPlusCardPricingModel ? t`minus the processing costs` : ''}, while{' '}
                            {formatAmount(feeAmount, locale, currency)} will go to {tenant?.name}.
                          </Trans>
                        </li>
                      );
                    })}
                  </ul>
                  <p className="text-xs leading-5 text-gray-400">
                    <Trans>
                      Note: Processing costs exclude gateway fees, which {tenant?.name} incurs
                      separately for authorization attempts and refunds. These are not part of
                      transaction fees.
                    </Trans>
                  </p>
                </Typography>
              </Label>
            )}

            {pricingModel === 'FLAT' && (
              <Label text={t`Percentage of Sale %`}>
                <Controller
                  control={control}
                  name="splitPercentageFlat"
                  render={({field: {ref, value, ...rest}}) => (
                    <InputText
                      ref={ref}
                      {...rest}
                      addonBefore="%"
                      value={readOnly && value ? value.toFixed(2) : value}
                      readOnly={readOnly}
                      disabled={readOnly}
                      hasError={Boolean(errors.splitPercentageFlat)}
                    />
                  )}
                />
                <ValidationError isVisible={Boolean(errors.splitPercentageFlat)}>
                  {errors.splitPercentageFlat?.message}
                </ValidationError>
              </Label>
            )}
          </div>
          <div className="sm:col-span-3">
            <Label text={t`Additional Per Transaction Fee`}>
              <InputText
                addonBefore={currencySymbol}
                {...register('splitFixed')}
                value={readOnly && splitFixed ? splitFixed.toFixed(2) : undefined}
                readOnly={readOnly}
                disabled={readOnly}
                hasError={Boolean(errors.splitFixed)}
              />
              <ValidationError isVisible={Boolean(errors.splitFixed)}>
                {errors.splitFixed?.message}
              </ValidationError>
              <Typography variant="field-helper" as="div" className="mt-2">
                <p className="text-xs leading-5 text-gray-400">
                  <Trans>For example</Trans>
                  {isCostPlusCardPricingModel && (
                    <Trans>
                      , with the 'Cost Plus' model, the merchant covers variable processing fees:
                    </Trans>
                  )}
                </p>
                <ul className="ml-5 text-xs text-gray-400 list-disc">
                  {[5, 100].map((exampleAmount, i) => {
                    const fees =
                      (exampleAmount / 100) * Number(splitPercentage) + Number(splitFixed);
                    const feeAmount = toMinorUnits(fees, currency);
                    const transactionAmount = toMinorUnits(exampleAmount - fees, currency);

                    return (
                      <li key={`example-${i}`}>
                        a {formatAmount(exampleAmount * 100, locale, currency)} purchase will have{' '}
                        {transactionAmount < 0 ? (
                          'fees greater than the transaction amount, so this payment amount would cause an error'
                        ) : (
                          <>
                            a {formatAmount(feeAmount, locale, currency)} fee.{' '}
                            {formatAmount(transactionAmount, locale, currency)}{' '}
                            {isCostPlusCardPricingModel ? t`minus the processing costs` : ''} will
                            go to merchant and {formatAmount(feeAmount, locale, currency)} to{' '}
                            {tenant?.name}
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </Typography>
            </Label>
          </div>
        </div>
      </Label>
      <Label text={t`Entry Mode`}>
        <InputSearchSelect
          className="mb-2"
          value={selectedEntryMode}
          onChange={(val: string | string[]) => {
            const selectedValue = Array.isArray(val) ? val[val.length - 1] : val;
            setValue('shopperInteraction', selectedValue as ShopperInteractionPublicEnum);
          }}
          options={shopperInteractionOptions.map(shopperInteraction => ({
            id: shopperInteraction,
            label: shopperInteractionLabels[shopperInteraction],
          }))}
          placeholder={t`Please select an entry Mode`}
        />
        <ValidationError isVisible={Boolean(errors.shopperInteraction)}>
          {errors.shopperInteraction?.message}
        </ValidationError>
      </Label>
      {children}
    </div>
  );
};
