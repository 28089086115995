import {useAuth0} from '@auth0/auth0-react';
import {I18n} from '@lingui/core';
import {useLingui} from '@lingui/react';
import {ErrorCode, UserErrorParams} from '@zentact/common';
import {ReactNode} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const getErrorDescription = (
  errorCode: ErrorCode,
  brandConfiguration: UserErrorParams['brandConfiguration'] | undefined,
  i18n: I18n
) => {
  const supportEmail = (
    <a
      className="pl-1 font-medium whitespace-nowrap"
      href={`mailto:${brandConfiguration?.supportEmail}`}
    >
      {brandConfiguration?.supportEmail}
    </a>
  );
  const translations: {[_ in ErrorCode]: ReactNode} = {
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    ERROR_GENERIC: i18n._('Something went wrong, if you need more help, please contact support.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    UNAUTHORIZED: i18n._("You don't have the required permissions to use this application."),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    PAGE_NOT_FOUND: i18n._('The page you are looking for does not exist.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    STATEMENT_NOT_FOUND: i18n._('The statement you are looking for does not exist.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    ACCESS_DENIED: i18n._(
      'You are not allowed to access this resource. If you believe this is not an intended behavior, please feel free to contact our support team for assistance.'
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    SCHEMA_VALIDATION_ERROR: 'SCHEMA_VALIDATION_ERROR',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_REGISTRATION_NOT_FOUND_OR_SESSION_EXPIRED: i18n._(
      'Merchant registration link is invalid or has expired, please contact support to request a new link.'
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_ACCOUNT_ALREADY_REGISTERED: 'MERCHANT_ACCOUNT_ALREADY_REGISTERED',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_REGISTRATION_NOT_FOUND_FOR_USER: i18n._(
      'Merchant registration is not found or the invitation is inactive. Please, try to sign in with an another user or report the problem.'
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_REGISTRATION_REVOKED: i18n._('Merchant registration is no longer valid.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_REGISTRATION_ALREADY_EXISTS: i18n._(
      'Merchant registration with the same business name already exists.'
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_WITH_SAME_NAME_ALREADY_EXISTS: i18n._('Merchant with the same name already exists.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_REGISTRATION_WITH_EMAIL_ALREADY_EXISTS: i18n._(
      'Another registration with the same email already exists.'
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_REGISTRATION_INVALID_STATUS: i18n._('Merchant registration has invalid status.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_ACCOUNT_IS_PENDING: i18n._('Merchant account is pending.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_REFERENCE_ID_ALREADY_EXISTS: i18n._(
      'Merchant with the same reference id already exists.'
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_REFERENCE_ID_REQUIRED: i18n._('Merchant Reference ID is required'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_ACCOUNT_NOT_FOUND: i18n._(
      'Sorry, we couldn`t find the merchant account. Please check the link and try again.'
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_IS_NOT_VERIFIED_IN_ADYEN: i18n._(
      'Merchant account is blocked. Additional information is required from Adyen.'
    ),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    MERCHANT_ACCOUNT_IS_INVALID: i18n._('Merchant account is not capable to perfom this action.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    CHECKOUT_ALREADY_COMPLETED: (
      <span>
        {i18n._(
          'Looks like a payment for this payment link has already been received. If you think this is an error, please contact support via email at'
        )}
        {supportEmail}.
      </span>
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    CHECKOUT_SESSION_EXPIRED: (
      <span>
        {i18n._(
          'For assistance with obtaining a new payment link, please reach out to us via email at '
        )}
        {supportEmail}.
      </span>
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    CHECKOUT_REFERENCE_ID_ALREADY_EXISTS: 'CHECKOUT_REFERENCE_ID_ALREADY_EXISTS',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    CHECKOUT_NOT_FOUND: i18n._('Checkout not found.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    CHECKOUT_ALREADY_PAID: i18n._('Checkout already paid.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    PAYMENT_NOT_FOUND: i18n._(
      'Sorry, we couldn`t find the payment. Please check the link and try again.'
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    NOT_SUPPORTED_LEGAL_ENTITY_TYPE: 'NOT_SUPPORTED_LEGAL_ENTITY_TYPE',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    ORGANIZATION_NOT_FOUND: 'ORGANIZATION_NOT_FOUND',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    ORGANIZATION_HAS_ACTIVE_MERHCNATS: 'ORGANIZATION_HAS_ACTIVE_MERHCNATS',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    ORGANIZATION_NAME_ALREADY_EXISTS: 'ORGANIZATION_NAME_ALREADY_EXISTS',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    ORGANIZATION_REFERENCE_ID_ALREADY_EXISTS: 'ORGANIZATION_REFERENCE_ID_ALREADY_EXISTS',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    ORGANIZATION_EMAIL_ALREADY_EXISTS: 'ORGANIZATION_EMAIL_ALREADY_EXISTS',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    NO_ACTIVE_ACCOUNTS_OR_REGISTRATIONS: i18n._(
      'No active accounts or registrations found for this user, please contact support.'
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    INVITATION_IS_EXPIRED_OR_NOT_FOUND: i18n._('Invitation has expired or not found.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    PAYMENT_NOT_AVAILABLE_TO_REFUND_OR_VOID: i18n._('Payment is not available to refund or void.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    PAYMENT_REFUSED: i18n._('Payment was refused.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    PARTIAL_REFUND_NOT_AVAILABLE: i18n._(
      'Partial refund is not available at the moment. Please try again later.'
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    USER_ALREADY_EXIST: i18n._('User already exists.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    BALANCE_ACCOUNT_IS_NOT_ACTIVE: (
      <span>
        {i18n._(
          'For assistance with obtaining a new payment link, please reach out to us via email at '
        )}
        {supportEmail}.
      </span>
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    LEGAL_BUSINESS_NAME_OR_ORGANIZATION_ID_IS_REQUIRED:
      'LEGAL_BUSINESS_NAME_OR_ORGANIZATION_ID_IS_REQUIRED',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    STATE_IS_INVALID: 'STATE_IS_INVALID',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    POSTAL_CODE_IS_INVALID: 'POSTAL_CODE_IS_INVALID',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    PHONE_NUMBER_IS_INVALID: 'PHONE_NUMBER_IS_INVALID',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    WEB_ADDRESS_IS_INVALID: i18n._('Web address is invalid.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    CURRENCY_NOT_SUPPORTED: i18n._('Currency is not supported.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    SESSION_EXPIRED: i18n._('Your session has expired.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MERCHANT_ACCOUNT_IS_NOT_ACTIVE: i18n._('Merchant account is not active.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    MISSING_PARAMETERS: i18n._('Missing parameters.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    USER_BELONGS_TO_ANOTHER_ORGANIZATION: i18n._('User belongs to another organization.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    WEBHOOK_HMAC_KEY_NOT_FOUND: i18n._('Webhook hmac Key not found.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    WEBHOOK_CONFIG_NOT_FOUND: i18n._('Webhook config not found.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    WEBHOOK_CONFIG_ALREADY_EXISTS: i18n._('Webhook config already exists.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    SPLIT_CONFIGURATION_GROUP_ALREADY_EXISTS: 'SPLIT_CONFIGURATION_GROUP_ALREADY_EXISTS',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    SPLIT_CONFIGURATION_GROUP_DOES_NOT_EXIST: 'SPLIT_CONFIGURATION_GROUP_DOES_NOT_EXIST',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    SPLIT_CONFIGURATION_GROUP_CANNOT_BE_SET_TO_NOT_DEFAULT:
      'SPLIT_CONFIGURATION_GROUP_CANNOT_BE_SET_TO_NOT_DEFAULT',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    SPLIT_CONFIGURATION_GROUP_IS_DEFAULT: 'SPLIT_CONFIGURATION_GROUP_IS_DEFAULT',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    SPLIT_CONFIGURATION_GROUP_IS_INACTIVE: 'SPLIT_CONFIGURATION_GROUP_IS_INACTIVE',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    NOT_ENOUGH_BALANCE: 'NOT_ENOUGH_BALANCE',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    SHOPPER_OR_STORED_PAYEMENT_METHOD_NOT_FOUND: i18n._(
      'Shopper or stored payment method not found.'
    ),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    STORED_PAYEMENT_METHOD_IS_NOT_ACTIVE: i18n._('Stored payment method is not active.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    STORED_PAYEMENT_METHOD_IS_EXPIRED: i18n._('Stored payment method is expired.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    SHOPPER_NOT_FOUND: i18n._('Stored is not found.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    SHOPPER_ALREADY_EXISTS: 'SHOPPER_ALREADY_EXISTS',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    SHOPPER_ID_ALREADY_EXISTS: 'SHOPPER_ID_ALREADY_EXISTS',
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    INVALID_PAYMENT_LIST_REQUEST: i18n._('Invalid Payment Request.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    FILE_TOO_BIG: i18n._('File too big.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    UNSUPPORTED_FILE_EXTENSION: i18n._('Unsupported file extension.'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    FILE_NAME_IS_INVALID: i18n._('File name is invalid.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    PSP_MERCHANT_ACCOUNT_NAME_NOT_FOUND: i18n._('PSP merchant account name not found.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    ADYEN_PERMISSION_NOT_ENABLED: i18n._('Adyen Permission Not Enabled.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    STORE_NOT_FOUND: i18n._('Store not found.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    STORE_IS_NOT_ACTIVE: i18n._('Store is not active.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    BALANCE_ACCOUNT_NOT_FOUND: i18n._('Balance account not found.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    STORE_NAME_IS_ALREADY_IN_USE: i18n._('Store name is already in use.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    STORE_REFERENCE_ID_ALREADY_EXISTS: i18n._('Store reference is already in use.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    TENANT_INTERVAL_FEE_GROUP_ALREADY_EXISTS: i18n._('Recurring fee group already exists.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    TENANT_INTERVAL_FEE_GROUP_NOT_FOUND: i18n._('Recurring fee group not found.'),
    // biome-ignore lint/style/useNamingConvention: ENUM to string map
    BALANCE_ACCOUNT_HAS_BALANCE: i18n._('Balance Account has not zero balance'),
  };
  return translations[errorCode];
};

const getErrorTitle = (e: ErrorCode, i18n: I18n) => {
  switch (e) {
    case ErrorCode.PAGE_NOT_FOUND:
      return i18n._('Page Not Found');
    case ErrorCode.CHECKOUT_SESSION_EXPIRED:
      return i18n._('Payment link has expired');
    case ErrorCode.ACCESS_DENIED:
      return i18n._('Permission Denied');
    case ErrorCode.PAYMENT_NOT_FOUND:
      return i18n._('Payment Not Found');
    case ErrorCode.CHECKOUT_ALREADY_COMPLETED:
      return i18n._('Checkout Completed');
    case ErrorCode.BALANCE_ACCOUNT_IS_NOT_ACTIVE:
      return i18n._('Merchant account is not active');
    case ErrorCode.MERCHANT_ACCOUNT_NOT_FOUND:
      return i18n._('Merchant Account Not Found');
    case ErrorCode.MERCHANT_REGISTRATION_NOT_FOUND_FOR_USER:
      return i18n._('Merchant Registration Not Found');
    case ErrorCode.UNAUTHORIZED: {
      return i18n._('Access denied');
    }
    default:
      return i18n._('There was a problem');
  }
};

type Props = {
  logoutUri?: string | null;
};

export const ErrorPage = ({logoutUri}: Props) => {
  const location = useLocation();
  const {logout} = useAuth0();

  const {brandConfiguration, ...state} = (location.state ?? {}) as {
    errorCode?: ErrorCode;
    brandConfiguration?: UserErrorParams['brandConfiguration'];
  };
  const errorCode = state.errorCode ?? ErrorCode.ERROR_GENERIC;
  const navigate = useNavigate();
  const {i18n} = useLingui();

  return (
    <section className="bg-primary-400 dark:bg-primary-900">
      <div className="container flex items-center justify-center min-h-screen px-6 py-12 mx-auto">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-2xl font-extrabold text-center text-white md:text-6xl">
            {getErrorTitle(errorCode, i18n)}
          </h1>
          <p className="mt-10 text-xl text-center text-white">
            {getErrorDescription(errorCode, brandConfiguration, i18n)}
          </p>
          <div className="mt-16 md:gap-x-3">
            <button
              type="button"
              onClick={() => navigate('/')}
              className="w-auto px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 rounded-lg bg-primary-500 hover:bg-primary-600 dark:bg-primary-800 dark:hover:bg-primary-700 shrink-0"
            >
              {i18n._('Go Home')}
            </button>
          </div>
          {(errorCode === ErrorCode.MERCHANT_REGISTRATION_NOT_FOUND_FOR_USER ||
            errorCode === ErrorCode.MERCHANT_REGISTRATION_REVOKED ||
            errorCode === ErrorCode.NO_ACTIVE_ACCOUNTS_OR_REGISTRATIONS ||
            errorCode === ErrorCode.UNAUTHORIZED) && (
            <div className="mt-2 md:gap-x-3">
              <button
                type="button"
                onClick={() => {
                  logout({logoutParams: {returnTo: `${window.location.origin}/logout`}});
                }}
                className="w-auto px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 rounded-lg bg-primary-500 hover:bg-primary-600 dark:bg-primary-800 dark:hover:bg-primary-700 shrink-0"
              >
                {i18n._('Log out')}
              </button>
            </div>
          )}
          {errorCode === ErrorCode.ACCESS_DENIED && logoutUri && (
            <div className="flex items-center mt-6 gap-x-3">
              <button
                type="button"
                onClick={() => {
                  if (logoutUri) {
                    window.location.href = logoutUri;
                  }
                }}
                className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 rounded-lg bg-primary-500 hover:bg-primary-600 dark:bg-primary-600 dark:hover:bg-primary-500 shrink-0 sm:w-auto"
              >
                {i18n._('Log out')}
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
